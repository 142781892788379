import { SectionWhoRangesRecord } from '@/modules/items/types/resultsTypes'

export const WHORanges: SectionWhoRangesRecord = {
	totalCount: {
		min: 0,
		max: 4000,
		maxSlider: 150,
		normal: 39,
	},
	totalMotileCount: {
		min: 0,
		max: 928,
		maxSlider: 112,
		normal: 20,
	},
	concentration: {
		min: 0,
		max: 259,
		maxSlider: 213,
		normal: 15,
		percentiles: [
			{
				min: 269,
				topPercent: 1,
			},
			{
				min: 213,
				topPercent: 5,
			},
			{
				min: 169,
				topPercent: 10,
			},
			{
				min: 116,
				topPercent: 25,
			},
		],
	},
	volume: {
		min: 0,
		normal: 1.5,
		maxSlider: 6.8,
		max: 7.6,
		percentiles: [
			{
				min: 7.6,
				topPercent: 1,
			},
			{
				min: 6.8,
				topPercent: 5,
			},
			{
				min: 6.0,
				topPercent: 10,
			},
			{
				min: 4.8,
				topPercent: 25,
			},
		],
	},
	motility: {
		min: 0,
		normal: 40,
		max: 81,
		maxSlider: 78,
		percentiles: [
			{
				min: 81,
				topPercent: 1,
			},
			{
				min: 78,
				topPercent: 5,
			},
			{
				min: 75,
				topPercent: 10,
			},
			{
				min: 69,
				topPercent: 25,
			},
		],
	},
	dnaFrag: {
		min: 0,
		normal: 45,
		max: 75,
		maxSlider: 75,
	},
	morphology: {
		min: 0,
		normal: 4,
		max: 14,
		maxSlider: 20,
	},
	numberOfVialsCryopreserved: {
		min: 1,
		max: 100,
		normal: 4,
		maxSlider: 0, // N/A
	},
	progressive_motility: {
		min: 1,
		max: 75,
		normal: 32,
		maxSlider: 0, // N/A
	},
	post_thaw_motility: {
		min: 0,
		max: 100,
		normal: 40,
		maxSlider: 0, // N/A
	},
}
