import { Data } from '@/modules/common/apiConfig'
import { ItemSteps } from '@/modules/onboarding/composables/useOnboarding'
import { DeliveryCarriers } from '@/modules/kitRegistrations/enums/kitRegistrationEnums'
import { Product, ShippingDetails } from '@/modules/checkout/types/checkoutTypes'

export type Item = Data<ItemAttributes>

export type Package = Data<PackageAttributes>

export type Addon = AddonAttributes

export enum StorageStatus {
	Empty = 'Empty',
	NotStored = 'Not stored',
	Stored = 'Stored',
	Withdrawn = 'Withdrawn',
}

export enum CryogenicTypes {
	Lifetime = 'Lifetime',
}

export enum ItemShippingStatus {
	ReadyForShipping = 'ready-for-shipping',
	ShippingDetailsSent = 'shipping-details-sent',
	Shipped = 'shipped',
}

export type ItemStatus = {
	previous_step?: number
	step: number
	tracking_number: string | null
	steps: string[]
	last_status_date: string
	shipped_at: string
	trackingNumberLink: string | null
	trackingReturnNumberLink: string | null
	trackingNumber: Record<string, string>
	trackingReturnNumber: string | null
}

export type SubscriptionProductCardType = {
	title: string
	perksSubtitle: string
	subtitle: string
	image: string
	reasons: string[]
}

export type ItemStorageStatus = {
	status: StorageStatus | null
	vials_stored_count: number
	vials_facilities_list: []
	days_to_secure: number
	vials_total_count: number
	withdrawn: boolean
	stored: boolean
	analysis_only: boolean
	last_vial_withdrawn_at: string
}

export type ItemAttributes = {
	id: number | undefined
	analysed_at: string
	approved_at: string
	sent_at: string
	cancelled_at: string
	analysis_date: string
	created_at: string
	category: string
	final_price: number | null
	title: string
	needs_mdi_validation: boolean
	po_number: string
	readable_status: string
	human_renewal_date: string
	is_supplement: boolean
	is_analysis: boolean
	is_sti: boolean
	is_cryogenic: boolean
	is_consultation: boolean
	has_health_insights: boolean
	not_viable: boolean
	considering_freezing: string | null
	shipping_status: ItemShippingStatus
	shipping_address: ShippingDetails
	status: ItemStatus
	preferred_delivery_date?: string | null
	progress_status: string
	shipped_at?: string
	product_type?: string
	storage_status?: ItemStorageStatus
	uuid: string
	sku?: string
	price?: string
	quantity?: number
	addons?: AddonAttributes[]
	vials: VialAttributes[]
	product?: Product
	package?: PackageAttributes
	delivery_carrier?: DeliveryCarriers
	with_dna_fragmentation: boolean
	kit_version?: number
	kit_has_conical_cup?: boolean
}

export type PackageAttributes = {
	name: string
	price: number
	uuid: string
}

export type AddonAttributes = {
	human_renewal_date: string
	product_type: string
	created_at?: string
	title?: string
	subscription?: SubscriptionAttributes
}

export type SubscriptionAttributes = {
	stripe_status?: string
}

export type Address = {
	address1: string
	address2: string
	city: string
	countryCode: string
	state: string
	zipCode: string
}

export type StorageFacility = {
	name: string
	address: Address
}

export type VialAttributes = {
	status: VialStatusesEnum
	storage_facility: StorageFacility
}

export type OnBoardingRequest = {
	item_uuid: string
	step: ItemSteps
	[key: string]: any
}

export const Packages = {
	NSW: 'NSW Package',
	INSURANCE: 'Today + 12 Months Storage',
}

export const VialStatuses = {
	PERMANENT_STORAGE: 'permanent_storage',
	AWAITING_PERMANENT_STORAGE: 'awaiting_permanent_storage',
	DESTROYED: 'destroyed',
}

export enum VialStatusesEnum {
	PermanentStorage = 'Permanent storage',
	AwaitingPermanentStorage = 'Awaiting permanent storage',
	Destroyed = 'Destroyed',
}

export type ProgressStatuses = {
	Completed: string
	Progress: string
}

export const ProgressStatusesEnum: ProgressStatuses = {
	Completed: 'completed',
	Progress: 'progress',
}

export enum SemenAnalysisStepsEnum {
	OrderConfirmed = 1,
	KitOnTheWay = 2,
	KitDeliveredToYou = 3,
	KitEnRouteToLab = 4,
	AnalysisInProgress = 5,
	ResultsReady = 6,
	Cancelled = 7,
	CheckEmail = 8,
}

export enum StiAnalysisStepsEnum {
	OrderConfirmed = 1,
	KitShipped = 2,
	AnalysisInProgress = 5,
	ResultsReady = 6,
	Cancelled = 7,
}

export type UnderstandingYourResultsSection = {
	title: string
	text: string
	image?: string
}

export type LifestyleRecommendation = {
	id: string
	title: string
	subtitle?: string
	description: string
	icon?: string
	image: string
	icon_name: string
	sort_order: number
}
