import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store'
import axios from 'axios'
import { AxiosList, Data } from '@/modules/common/apiConfig'
import { Urologist } from '@/modules/urologists/types/urologistTypes'

export type State = {
	zip_code: string
	urologists: Data<Urologist>[] | null
}

export const state = (): State => ({
	zip_code: '',
	urologists: null,
})

const mutations: MutationTree<State> = {
	setZipCode(state: State, zip_code: string) {
		state.zip_code = zip_code
	},
	setUrologists(state: State, urologists: Data<Urologist>[]) {
		state.urologists = urologists
	},
}

const actions: ActionTree<State, RootState> = {
	async findUrologists({ commit, state }, zipCode: string): Promise<any> {
		const { data }: AxiosList<Urologist> = await axios.get(
			`/restify/verified-urologists?postal_code=${zipCode}&perPage=999999`,
		)
		commit('setUrologists', data)
		commit('setZipCode', zipCode)

		return data
	},
}

const getters: GetterTree<State, RootState> = {}

const module: Module<State, RootState> = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

export default module
