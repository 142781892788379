<template>
	<component
		:is="componentTag"
		v-bind="attrs"
		@click="handleClick"
	>
		<slot />
	</component>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { IconsPropType } from '@/modules/common/icons'

export type NavLinkType = {
	name: string
	link: string
	method?: Function
	badge?: string
	icon?: IconsPropType
	class?: string
	analytics?: () => void
	badgeVisibility?: boolean
	enabled?: () => boolean
}
export default defineComponent({
	props: {
		item: {
			type: Object as PropType<NavLinkType>,
			default: () => ({}),
		},
	},
	computed: {
		componentTag(): string {
			if (this.isExternalLink) {
				return 'a'
			}
			if (this.item.method && !this.item.link) {
				return 'button'
			}
			return 'router-link'
		},
		isExternalLink() {
			return this.item.link?.startsWith('http')
		},
		attrs() {
			const attrs: any = {
				...this.$attrs,
				class: this.item.class,
			}
			if (this.isExternalLink) {
				attrs.href = this.item.link
				attrs.rel = 'noopener'
				attrs.target = '_blank'
			} else {
				attrs.to = this.item.link
			}
			return attrs
		},
	},
	methods: {
		handleClick(): void {
			if (this.item.analytics) {
				this.item.analytics()
			}

			if (this.item.method) {
				this.item.method()
			}
		},
	},
})
</script>
