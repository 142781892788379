import { REMOTE_LOGGING_PROXY_URL, REMOTE_LOGGING_API_KEY } from '@/modules/common/config'

export interface RemoteLogArgs {
	message: string
	context?: object
	displayConsole?: boolean
}

export class RemoteLog {
	private static logToRemote(level: string, message: string, context: object) {
		fetch(REMOTE_LOGGING_PROXY_URL, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-api-key': REMOTE_LOGGING_API_KEY,
			},
			body: JSON.stringify({
				level: level,
				message: message,
				context,
			}),
		}).catch(() => {
			console.error('Logging to Remote Logging Failed')
		})
	}

	public static info(args: RemoteLogArgs) {
		this.logToRemote('INFO', args.message, args.context ?? {})
		if (args.displayConsole) {
			console.info(args.message, args.context)
		}
	}

	public static debug(args: RemoteLogArgs) {
		this.logToRemote('DEBUG', args.message, args.context ?? {})
		if (args.displayConsole) {
			console.debug(args.message, args.context)
		}
	}

	public static warning(args: RemoteLogArgs) {
		this.logToRemote('WARNING', args.message, args.context ?? {})
		if (args.displayConsole) {
			console.warn(args.message, args.context)
		}
	}

	public static error(args: RemoteLogArgs) {
		this.logToRemote('ERROR', args.message, args.context ?? {})
		if (args.displayConsole) {
			console.error(args.message, args.context)
		}
	}
}
