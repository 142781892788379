import { App } from 'vue'
import copyToClipboard from '@/plugins/copyToClipboard'
import NotificationsPlugin from '@/components/common/NotificationPlugin'
import dateFormatPlugin from '@/plugins/dateFormatPlugin'
import iconsPlugin from '@/plugins/iconsPlugin'
import veeValidate from '@/plugins/veeValidate'
import clickOutside from '@/directives/clickOutside'
import focusDirective from '@/directives/focus'
import userPlugin from '@/plugins/userPlugin'
import formatPricePlugin from '@/plugins/formatPricePlugin'
import formatPercentPlugin from '@/plugins/formatPercentPlugin'

export default {
	install(Vue: App) {
		Vue.use(copyToClipboard)
		Vue.use(dateFormatPlugin)
		Vue.use(formatPricePlugin)
		Vue.use(formatPercentPlugin)
		Vue.use(veeValidate)
		Vue.use(iconsPlugin)
		Vue.use(userPlugin)
		Vue.use(NotificationsPlugin)
		Vue.directive('click-outside', clickOutside)
		Vue.directive('focus', focusDirective)
	},
}
