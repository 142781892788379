// define api url here
const DEFAULT_API_URL = 'https://develop.givelegacy.com/api'
export const API_URL = import.meta.env.VITE_API_URL || DEFAULT_API_URL
export const API_URL_BASE = API_URL?.toString().replace('/api', '')
export const DEFAULT_APP_TITLE = 'Legacy'
export const ID_ME_CLIENT_ID = import.meta.env.VITE_ID_ME_CLIENT_ID
export const ID_ME_REDIRECT_URI = import.meta.env.VITE_ID_ME_REDIRECT_URI

export const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN
export const AUTH0_CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID
export const AUTH0_CALLBACK_URL = import.meta.env.VITE_AUTH0_CALLBACK_URL

export const REMOTE_LOGGING_PROXY_URL = import.meta.env.VITE_REMOTE_LOGGING_PROXY_URL
export const REMOTE_LOGGING_API_KEY = import.meta.env.VITE_REMOTE_LOGGING_API_KEY
export const SENTRY_BASE_URL = import.meta.env.VITE_SENTRY_BASE_URL
