export enum TrueFalseValues {
	True = '1',
	False = '0',
}

export enum CheckoutSteps {
	AccountDetails = 'account_details',
	GiftDetails = 'gift_details',
	ShippingDetails = 'shipping_details',
	PaymentMethod = 'payment_method',
	ReviewFinal = 'review_final',
}

export enum PaymentType {
	SPLITIT = 'splitit',
	STRIPE = 'stripe',
}

export enum BillingType {
	SAME_AS_SHIPPING = 'same_as_shipping',
	DIFFERENT = 'different_billing_address',
}
