<template>
	<svg
		aria-hidden="true"
		viewBox="0 0 24 24"
	>
		<use :href="symbolId" />
	</svg>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import { IconsPropType } from '@/modules/common/icons'

export default defineComponent({
	name: 'Icon',
	props: {
		prefix: {
			type: String,
			default: 'icon',
		},
		name: {
			type: String as PropType<IconsPropType>,
			required: true,
		},
	},
	setup(props) {
		const symbolId = computed(() => `#${props.prefix}-${props.name}`)
		return { symbolId }
	},
})
</script>
