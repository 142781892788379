export enum Icons {
	Alcohol = 'alcohol',
	Airplay = 'airplay',
	AlertCircle = 'alert-circle',
	AlertOctagon = 'alert-octagon',
	AlertTriangle = 'alert-triangle',
	Archive = 'archive',
	ArrowDown = 'arrow-down',
	ArrowDownCircle = 'arrow-down-circle',
	ArrowDownLeft = 'arrow-down-left',
	ArrowDownRight = 'arrow-down-right',
	ArrowLeft = 'arrow-left',
	ArrowLeftCircle = 'arrow-left-circle',
	ArrowRight = 'arrow-right',
	ArrowRightCircle = 'arrow-right-circle',
	ArrowUp = 'arrow-up',
	ArrowUpCircle = 'arrow-up-circle',
	ArrowUpLeft = 'arrow-up-left',
	ArrowUpRight = 'arrow-up-right',
	award = 'award',
	Asterisk = 'asterisk',
	Bacteria = 'bacteria',
	Bacteria2 = 'bacteria-2',
	BarChart = 'bar-chart',
	BarChart2 = 'bar-chart-2',
	Bell = 'bell',
	BellOff = 'bell-off',
	BMI = 'bmi',
	Book = 'book',
	BookOpen = 'book-open',
	Bookmark = 'bookmark',
	Briefcase = 'briefcase',
	Calendar = 'calendar',
	Camera = 'camera',
	Cast = 'cast',
	CardVisa = 'card-visa',
	CardDiscover = 'card-discover',
	CardMasterCard = 'card-mastercard',
	CardAmericanExpress = 'card-american-express',
	Check = 'check',
	Check2 = 'check-2',
	CheckCircle = 'check-circle',
	Circle = 'circle-',
	Clipboard = 'clipboard',
	Clock = 'clock',
	CloudOff = 'cloud-off',
	Compass = 'compass',
	Copy = 'copy',
	CreditCard = 'credit-card',
	CryogenicVial = 'cryogenic-vial',
	CryogenicVial2 = 'cryogenic-vial-2',
	Database = 'database',
	Diet = 'diet',
	DollarSign = 'dollar-sign',
	Dot = 'dot',
	Doctor = 'doctor',
	Download = 'download',
	DownloadCloud = 'download-cloud',
	Droplet = 'droplet',
	Edit = 'edit',
	Edit2 = 'edit-2',
	Edit3 = 'edit-3',
	Exercise = 'exercise',
	ExternalLink = 'external-link',
	ExternalLink2 = 'external-link-2',
	Eye = 'eye',
	EyeOff = 'eye-off',
	Facebook = 'facebook',
	Feather = 'feather',
	File = 'file',
	FileMinus = 'file-minus',
	FilePlus = 'file-plus',
	FileText = 'file-text',
	Filter = 'filter',
	Flag = 'flag',
	Folder = 'folder',
	FolderMinus = 'folder-minus',
	FolderPlus = 'folder-plus',
	Freeze = 'Freeze',
	Frown = 'frown',
	Gift = 'gift',
	Globe = 'globe',
	Gmp = 'gmp',
	HardDrive = 'hard-drive',
	Headphones = 'headphones',
	Heart = 'heart',
	HeartPlus = 'heart-plus',
	HelpCircle = 'help-circle',
	Home = 'home',
	HotTub = 'hottub',
	Image = 'image',
	Inbox = 'inbox',
	Info = 'info',
	InfoBlue = 'info-blue',
	InsuranceShield = 'insurance-shield',
	Instagram = 'instagram',
	Key = 'key',
	Kit = 'kit',
	LifeBuoy = 'life-buoy',
	Link = 'link',
	Link2 = 'link-2',
	Linkedin = 'linkedin',
	List = 'list',
	Loading = 'loading',
	Lock = 'lock',
	LogIn = 'log-in',
	LogOut = 'log-out',
	Mail = 'mail',
	Map = 'map',
	MapPin = 'map-pin',
	Marijuana = 'marijuana',
	Maximize = 'maximize',
	Maximize2 = 'maximize-2',
	Meh = 'meh',
	Menu = 'menu',
	MessageCircle = 'message-circle',
	MessageSquare = 'message-square',
	Mic = 'mic',
	MicOff = 'mic-off',
	Minimize = 'minimize',
	Minimize2 = 'minimize-2',
	Minus = 'minus',
	MinusCircle = 'minus-circle',
	MinusSquare = 'minus-square',
	Monitor = 'monitor',
	Moon = 'moon',
	MoreHorizontal = 'more-horizontal',
	MoreVertical = 'more-vertical',
	Music = 'music',
	Navigation = 'navigation',
	Navigation2 = 'navigation-2',
	NoDiary = 'no-diary',
	NoGluten = 'no-gluten',
	NoPreservatives = 'no-preservatives',
	NoSugar = 'no-sugar',
	NonGmo = 'non-gmo',
	Note = 'note',
	Nsf = 'nsf',
	Package = 'package',
	Paperclip = 'paperclip',
	Percent = 'percent',
	Phone = 'phone',
	PhoneCall = 'phone-call',
	PhoneForward = 'phone-forward',
	PhoneIncoming = 'phone-incoming',
	PhoneMissed = 'phone-missed',
	PhoneOff = 'phone-off',
	PhoneOutgoing = 'phone-outgoing',
	PieChart = 'pie-chart',
	Play = 'play',
	Plus = 'plus',
	PlusCircle = 'plus-circle',
	PlusSquare = 'plus-square',
	Printer = 'printer',
	Rectangle55 = 'Rectangle 55',
	RefreshCcw = 'refresh-ccw',
	RefreshCw = 'refresh-cw',
	Search = 'search',
	Send = 'send',
	Server = 'server',
	Settings = 'settings',
	Share = 'share',
	Share2 = 'share-2',
	Shield = 'shield',
	ShieldOff = 'shield-off',
	ShoppingBag = 'shopping-bag',
	ShoppingCart = 'shopping-cart',
	Sitting = 'sitting',
	Slash = 'slash',
	Sleep = 'sleep',
	Sliders = 'sliders',
	Smartphone = 'smartphone',
	Smile = 'smile',
	Smoking = 'smoking',
	Specialist = 'specialist',
	Spinner = 'spinner',
	Splitit = 'splitit-logo',
	Sperm = 'sperm',
	Sperm2 = 'sperm-2',
	Star = 'star',
	Supplements = 'supplements',
	Tablet = 'tablet',
	Tag = 'tag',
	ChevronDown = 'chevron-down',
	ChevronLeft = 'chevron-left',
	ChevronRight = 'chevron-right',
	ChevronUp = 'chevron-up',
	ThumbsDown = 'thumbs-down',
	ThumbsUp = 'thumbs-up',
	Tool = 'tool',
	Trash = 'trash',
	Trash2 = 'trash-2',
	Truck = 'truck',
	Tv = 'tv',
	Twitter = 'twitter',
	Umbrella = 'umbrella',
	Unlock = 'unlock',
	Upload = 'upload',
	UploadCloud = 'upload-cloud',
	UsaMade = 'usa-made',
	User = 'user',
	UserCheck = 'user-check',
	UserCircle = 'user-circle',
	UserMinus = 'user-minus',
	UserPlus = 'user-plus',
	UserX = 'user-x',
	Users = 'users',
	Video = 'video',
	VideoOff = 'video-off',
	Voicemail = 'voicemail',
	Volume = 'volume',
	Volume1 = 'volume-1',
	Volume2 = 'volume-2',
	VolumeX = 'volume-x',
	Watch = 'watch',
	Weight = 'weight',
	Wifi = 'wifi',
	WifiOff = 'wifi-off',
	X = 'x',
	XCircle = 'x-circle',
	XOctagon = 'x-octagon',
	XSquare = 'x-square',
	Youtube = 'youtube',
	Zap = 'zap',
	ZapOff = 'zap-off',
	ZoomIn = 'zoom-in',
	ZoomOut = 'zoom-out',
	IconMedical = 'icon-medical',
	IconPerson = 'icon-person',
	IconStroller = 'icon-stroller',
	IconWorker = 'icon-worker',
}

export type IconsType = typeof Icons
export type IconsPropType = Icons | `${Icons}` | undefined
