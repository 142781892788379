import { CustomHTMLElement, DirectiveBinding } from '@vue/runtime-core'

type Element = HTMLElement | null

export default {
	mounted(el: CustomHTMLElement, binding: DirectiveBinding) {
		if (binding.hasOwnProperty('value') && binding.value === false) {
			return
		}

		const input = el.querySelector('input')
		const textarea = el.querySelector('textarea')
		const select = el.querySelector('select')
		const button = el.querySelector('button')

		let elements: Element[] = [input, textarea, select, button]
		const focusableElement = elements.find(el => el && el.focus)

		const elementToFocus: Element = focusableElement || el
		const delay = binding.value || 0

		setTimeout(() => {
			elementToFocus?.focus()
		}, delay)
	},
}
