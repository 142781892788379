import { createApp } from 'vue'
// @ts-ignore
import App from './App.vue'
import router from './router/router'
import i18n from '@/i18n'
import store from '@/store'
import globalPlugins from '@/plugins/globalPlugins'
import { sync } from 'vuex-router-sync'
import pwa from '@/plugins/pwa'
import '@/assets/css/index.scss'
// @ts-ignore
import 'virtual:svg-icons-register'
import './polyfills'
import * as Sentry from '@sentry/vue'
import { API_URL, SENTRY_BASE_URL } from '@/modules/common/config'

sync(store, router)

const app = createApp(App).use(store).use(i18n).use(router).use(globalPlugins).use(pwa)

app.use(
	createAuth0({
		domain: AUTH0_DOMAIN,
		clientId: AUTH0_CLIENT_ID,
		cookieDomain: '.givelegacy.com',
		cacheLocation: 'localstorage',
		authorizationParams: {
			redirect_uri: AUTH0_CALLBACK_URL,
			audience: 'https://dashboard.givelegacy.com/',
		},
	}),
)

Sentry.init({
	app,
	dsn: SENTRY_BASE_URL,
	tracePropagationTargets: ['localhost', API_URL],
	integrations: [
		new Sentry.BrowserTracing({
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
		}),
		new Sentry.Replay(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
})

app.mount('#app')

import './modules/common/apiConfig'
import { createAuth0 } from '@auth0/auth0-vue'
import { AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_CALLBACK_URL } from '@/modules/common/config'
