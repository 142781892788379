import { PackagesUuid } from '@/modules/checkout/enum/packagesUuid'

export const WEBSITE_URL = import.meta.env.VITE_WEBSITE_URL || 'https://www.givelegacy.com'

export const CRYO_STORAGE_LINK = getWebsiteLink('/sperm-freezing/')
export const CD_CRYO_STORAGE_LINK = 'buy-cryogenic'
export const ORDER_LINK = getWebsiteLink('/order/#product-comparison-table')
export const ORDER_KIT_LINK = getWebsiteLink('/order/#plans')
export const ADD_PRODUCT_TO_CART_LINK = getWebsiteLink('/sperm-improvement/?addProductUuid={uuid}')
export const RESOURCES_LINK = getWebsiteLink('/resources')
export const CONTACT_LINK = getWebsiteLink('/contact')
export const HELP_CENTER_LINK = 'https://help.givelegacy.com/s/'
export const TMC_LEARN_MORE_LINK = getWebsiteLink('/resources/total-motile-sperm-count-2/')
export const CONCENTRATION_LEARN_MORE_LINK = getWebsiteLink('/sperm-testing-guide/#parameters')
export const VOLUME_LEARN_MORE_LINK = getWebsiteLink('/sperm-testing-guide/#parameters')
export const MOTILITY_LEARN_MORE_LINK = getWebsiteLink('/sperm-testing-guide/#parameters')
export const MORPHOLOGY_LEARN_MORE_LINK = getWebsiteLink('/sperm-testing-guide/#parameters')
export const SPERM_ANALYSIS_LINK = getWebsiteLink('/sperm-analysis')
export const DNA_FRAGMENTATION_LEARN_MORE_LINK = getWebsiteLink(
	'/sperm-dna-fragmentation-analysis/',
)
export const BUY_FOR_TODAY_DNA_FRAGMENTATION_LINK = getWebsiteLink('/for-today-dna-fragmentation/')
export const SPERM_FREEZING_LINK = getWebsiteLink('/sperm-freezing/#packages-with-storage')
export const STI_TEST_KIT_LINK = getWebsiteLink('/sti-test-kit')
export const MFS_LINK = getWebsiteLink('/supplements-male-fertility')
export const COQ10_LINK = getWebsiteLink('/supplements-coq10')
export const ADD_STORAGE_LINK = getWebsiteLink('/sperm-freezing/#cryo-storage')
export const COMMUNITY_LINK = 'https://legacy.circle.so'
export const EVENT_LINK = 'https://legacy.circle.so/home'

export const GOOGLE_AUTHENTICATOR_LINK =
	'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en'
export const TYPEFORM_LINK =
	'https://admin.typeform.com/signup?utm_campaign=PyWfTz&utm_source=typeform.com-01DKCWX7XWJB29GHFDHGXZS761-professional&utm_medium=typeform&utm_content=typeform-embedded-poweredbytypeform&utm_term=EN'
export const ORDER_LINK_ONLY = getWebsiteLink('/order')
export const ADVISOR_PHONE_LINK = 'tel:+16175140901'
export const CX_PHONE_LINK = 'tel:6175140901'
export const KIT_VIDEO_WALK_THROUGH_LINK =
	'https://player.vimeo.com/progressive_redirect/playback/838899568/rendition/720p/file.mp4?loc=external&log_user=0&signature=34d3a4f23f3be14c28be78e7705296a5fb1c0ecfeeb10e94e58bf56319deb1e5'
export const KIT_V3_VIDEO_WALK_THROUGH_LINK =
  'https://player.vimeo.com/progressive_redirect/playback/986411022/rendition/720p/file.mp4?loc=external&log_user=0&signature=772a748a2efe5bb8c6560258de97477079b4295bc13433e3499f6cfa49bfc40d'
export const FEDEX_DROPBOX_LINK = 'https://www.fedex.com/en-us/shipping/ship-centers.html'
export const FEDEX_SHIPPING_CENTERS_LINK = 'https://www.fedex.com/en-us/shipping/ship-centers.html'
export const FEDEX_SCHEDULE_PICKUP_LINK =
	'https://www.fedex.com/en-us/shipping/schedule-manage-pickups.html'
export const UPS_PICKUP_LINK =
	'https://www.ups.com/us/en/business-solutions/pickup-dropoff-options/ups-on-call-pickup.page'
export const UPS_DROPBOX_LINK = 'https://www.ups.com/dropoff?loc=en_US'
export const CLIENT_CHECKOUTS_LINK = 'https://client.givelegacy.com/checkouts'

export const FERTILITY_PACKAGE_CHECKOUT_LINK = getCheckoutUrl(
	PackagesUuid.FERTILITY_CONSULTATION_UUID,
	1,
)

export function getWebsiteLink(path: string): string {
	return WEBSITE_URL + path
}

export function getLifestyleSurveyLink(userId: string | undefined): string {
	if (!userId) {
		return ''
	}
	return `https://givelegacy.typeform.com/to/bO3oB5OM?targetID=${userId}`
}

export function getCheckoutUrl(packageUuid: string, quantity: number): string {
	return '/checkouts?items=' + JSON.stringify([{ product_id: packageUuid, qty: quantity }])
}
