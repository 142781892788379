<script lang="ts">
import { defineComponent } from 'vue'
import store from '@/store'

export default defineComponent({
	beforeRouteEnter(to, from, next) {
		const isLoggedIn = store.state?.auth?.isLoggedIn
		const user = store.state?.auth?.user
		if (isLoggedIn && user?.has_authorized_user_role) {
			return next('/analysis')
		}
		if (isLoggedIn) {
			return next('/home')
		}
		next('/login')
	},
})
</script>
