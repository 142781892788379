import { createRouter, createWebHistory } from 'vue-router'
import { setupLayouts } from 'virtual:generated-layouts'
import generatedRoutes from 'virtual:generated-pages'

const routes = [
	...setupLayouts(generatedRoutes),
	// We had a typo in the url - word 'eligability', so we are creating
	// a redirect to help protect us from missed references.
	{
		path: '/insurance/eligability/win-fertility/',
		redirect: '/insurance/eligibility/win-fertility/',
	},
]

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes,
	scrollBehavior: to => {
		if (to) {
			return { selector: to.hash }
		}
		return { left: 0, top: 0 }
	},
})

export default router
