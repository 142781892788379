import i18n from '@/i18n'
import { ScrollableSection } from '@/modules/common/types/common'

export enum StiResultsSections {
	UnderstandingYourResultsSti = 'understanding-your-results-sti',
	Chlamydia = 'chlamydiaGenital',
	Gonorrhea = 'gonorrheaGenital',
	HepatitisB = 'hepatitisB',
	HepatitisC = 'hepatitisCAntibody',
	HIV = 'hiv',
	Syphilis = 'syphilis',
	SyphilisIGG = 'syphilisIGG',
}

export enum StiAnalysisResultTypes {
	Positive = 'positive',
	Negative = 'negative',
	Inconclusive = 'inconclusive',
}

export enum StiStates {
	SomePositive = 'Some_Positive',
	AllTestsNegative = 'All_Tests_Negative',
	SomeInconclusive = 'Some_Inconclusive',
	SomeInconclusiveAndSomePositive = 'Some_Inconclusive_And_Some_Positive',
}

export type StiResult = {
	name: StiResultsSections
	note: string | null
	readable: StiAnalysisResultTypes
	section: StiResultsSections
	reference: string | null
	units: string | null
	result: string
	isConfirmatory: boolean
}

export const stiTestLabels = {
	[StiResultsSections.UnderstandingYourResultsSti]: i18n.t('Understanding your results'),
	[StiResultsSections.Chlamydia]: i18n.t('Chlamydia'),
	[StiResultsSections.Gonorrhea]: i18n.t('Gonorrhea'),
	[StiResultsSections.HepatitisB]: i18n.t('Hepatitis B'),
	[StiResultsSections.HepatitisC]: i18n.t('Hepatitis C'),
	[StiResultsSections.HIV]: i18n.t('HIV'),
	[StiResultsSections.Syphilis]: i18n.t('Syphilis'),
	[StiResultsSections.SyphilisIGG]: i18n.t('Syphilis'),
}

export const stiAnalysisScrollableSections: Array<ScrollableSection> = [
	{
		label: i18n.t('Understanding your results'),
		href: StiResultsSections.UnderstandingYourResultsSti,
		observer: {
			isIntersecting: false,
			intersectionRatio: 0,
		},
	},
	{
		label: i18n.t('Chlamydia'),
		href: StiResultsSections.Chlamydia,
		observer: {
			isIntersecting: false,
			intersectionRatio: 0,
		},
	},
	{
		label: i18n.t('Gonorrhea'),
		href: StiResultsSections.Gonorrhea,
		observer: {
			isIntersecting: false,
			intersectionRatio: 0,
		},
	},
	{
		label: i18n.t('Hepatitis B'),
		href: StiResultsSections.HepatitisB,
		observer: {
			isIntersecting: false,
			intersectionRatio: 0,
		},
	},
	{
		label: i18n.t('Hepatitis C'),
		href: StiResultsSections.HepatitisC,
		observer: {
			isIntersecting: false,
			intersectionRatio: 0,
		},
	},
	{
		label: i18n.t('HIV'),
		href: StiResultsSections.HIV,
		observer: {
			isIntersecting: false,
			intersectionRatio: 0,
		},
	},
	{
		label: i18n.t('Syphilis'),
		href: StiResultsSections.Syphilis,
		observer: {
			isIntersecting: false,
			intersectionRatio: 0,
		},
	},
	{
		label: i18n.t('Syphilis'),
		href: StiResultsSections.SyphilisIGG,
		observer: {
			isIntersecting: false,
			intersectionRatio: 0,
		},
	},
]

export type StiResultsMessageSection = {
	title: string
	stateMessages: Partial<Record<StiAnalysisResultTypes, string>>
	showIf?: (resultType: StiAnalysisResultTypes) => boolean
}

export type StiItemMessages = {
	title: string
	description: string
	subheader: Record<StiAnalysisResultTypes, string>
	sections: StiResultsMessageSection[]
}

export type StiAllItemsMessages = Partial<Record<StiResultsSections, StiItemMessages>>
