import { useStore } from 'vuex'
import { UserType } from '@/modules/auth/types/authTypes'
import { computed } from 'vue'

export default function useUser(): UserType {
	const store = useStore()
	return store.state.auth.user
}

export function useUserNames() {
	const user = useUser()

	const userPreferredFullName = computed<string>(() => {
		if (user.preferred_first_name) {
			return `${user.preferred_first_name} ${user.last_name}`
		}

		return user.name
	})

	const userPreferredFirstName = computed<string>(() => {
		return user.preferred_first_name ? user.preferred_first_name : user.first_name
	})

	return {
		userPreferredFullName,
		userPreferredFirstName,
	}
}
