import { ResultDocument } from '@/modules/items/types/resultsTypes'
import { State } from '@/modules/auth/store/authModule'

export const authTestingPanelMutations = {
	updateClientValue(state: State, { key, value }: { key: string; value: number | null }) {
		const client = state.user.client
		if (!client) {
			return
		}

		client!.attributes[key] = value
	},
	setLatestAnalysisValue(state: State, { key, value }: { key: string; value: string | number }) {
		state.user.client!.attributes!.latest_analysis[key] = value
	},
	setLatestResultValue(state: State, { key, value }: { key: string; value: string | number }) {
		state.user.client!.relationships!.latest_ready_result[key] = value
	},
	setLatestReadyResult(state: State, { result }: { result: ResultDocument }) {
		state.user.client!.relationships!.latest_ready_result = result
	},
}
