import { App } from 'vue'

const DEFAULT_PERCENTAGE_FORMAT_OPTIONS: Intl.NumberFormatOptions = {
	style: 'percent',
	minimumFractionDigits: 0,
	maximumFractionDigits: 2,
}

const DEFAULT_LOCALE = 'en-US'

export function formatPercent(
	value: number | null,
	options: Intl.NumberFormatOptions = DEFAULT_PERCENTAGE_FORMAT_OPTIONS,
	locale = DEFAULT_LOCALE,
) {
	options = {
		...DEFAULT_PERCENTAGE_FORMAT_OPTIONS,
		...options,
	}
	if (value === null || value === undefined) {
		return '- -'
	}
	const formatter = new Intl.NumberFormat(locale, options)
	return formatter.format(value / 100)
}

export default {
	install(Vue: App) {
		Vue.config.globalProperties.$formatPercent = formatPercent
	},
}
