export enum ProductsUuid {
	ANALYSES_UUID = '0c57347c-c52d-45d3-a7ee-bf2300c0b9cd',
	BRONZE_UUID = 'a1b778c3-2c65-4989-9cdc-7f3d96fab501',
	RESULTS_REVIEW_UUID = '25965d7b-098b-4254-8135-00992a312fd7',
	CRYO_5_UUID = 'a07809d3-7dab-43f8-b1cb-2b1c26e7a89d',
	CRYO_5_TOMORROW_UUID = 'b3a00c60-a504-4454-a842-2266fcd2d2e7',
	FREE_CRYO_SUBSCRIPTION = '33112cd3-905a-4677-b5dc-de40ad344af8',
	CRYO_LIFETIME_UUID = 'd168091a-8c53-419c-a613-aec6074df0d5',
	DNA_UUID = 'e0a1aa9c-5597-4203-9760-5d354f002864',
	COQ10_UUID = '3F22169E-7EDA-49DF-AC06-A1476FEE261D',
	MALE_FERTILITY_SUPPLEMENT_UUID = '5d0b2e36-cf04-4f19-83a3-a66354cb8e7d',
	TOMORROW_UUID = 'a1b778c3-2c65-4989-9cdc-7f3d96fab505',
	PLATINUM_UUID = 'a1b778c3-2c65-4989-9cdc-7f3d96fab500',
	POST_VASECTOMY_UUID = '1a0f3943-001e-4b4d-9f59-51c16af286ff',
	MONTHLY_VASECTOMY_UUID = '8b55be07-bcb6-4417-8db5-40528b3f100d',
	CRYO_MONTHLY_UUID = 'dc7258dc-69bf-4122-ab16-97aa20ef707f',
	CRYO_YEARLY_UUID = '8ed3cfcf-2cfe-4fa6-973f-107d6b6906ca',
	CRYO_SIX_MONTHS_UUID = '7e62549b-7a63-462b-b2ef-382437f3204f',
	CRYO_TEN_YEARS_UUID = '35a39b22-50f8-4891-bb27-1131fcc9a9fa',
	BRONZE_PACKAGE_FSA_UUID = '2ddc2ada-7873-44b8-a7b6-ef02eed2b178',
	FERTILITY_SUPLEMENTS_UUID = '8f53623c-28eb-42e0-bac8-e2aea26236d4',
	WITHDRAWAL_CRYOSHIPPING_UUID = '538ee8dc-7115-4b0d-91de-4a41777d5b5b',
	FERTILITY_CONSULTATION_UUID = '626c107c-2917-4345-8287-2dca7522ef1c',
	STI_UUID = '803e90aa-52d5-4cc3-9685-6f81fe9fdd29',
	BUFFER_MEDIA_REPLACEMENT = '5d6bf3ca-f8b0-476d-ac25-ca5755ca7d05',
}
