import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store'
import { AxiosList, Data } from '@/modules/common/apiConfig'
import { Document } from '@/modules/documents/types/documentTypes'
import axios from 'axios'
import { DOCUMENT_STEPS } from '@/modules/documents/enums/documentEnums'

export type State = {
	contract: Document | null
	contract_poa: Document | null
	contract_sti: Document | null
	contract_vha_crada: Document | null
}

export const state = (): State => ({
	contract: null,
	contract_poa: null,
	contract_sti: null,
	contract_vha_crada: null,
})

const mutations: MutationTree<State> = {
	setContract(state: State, document: Document) {
		state.contract = document
	},
	setContractPOA(state: State, contract: Document) {
		state.contract_poa = contract
	},
	setContractSti(state: State, contract: Document) {
		state.contract_sti = contract
	},
	setContractVhaCrada(state: State, contract: Document) {
		state.contract_vha_crada = contract
	},
}

const actions: ActionTree<State, RootState> = {
	async getContracts({ commit }): Promise<any> {
		const params = {
			['step']: '6,29,30',
		}

		const { data }: AxiosList<Document> = await axios.get('/restify/documents', {
			params,
		})

		const client_agreement: Data<Document> | undefined = data.find(
			(document: Data<Document>) => document.attributes.step == DOCUMENT_STEPS.client_agreement,
		)
		const sti_client_agreement: Data<Document> | undefined = data.find(
			(document: Data<Document>) => document.attributes.step == DOCUMENT_STEPS.sti_client_agreement,
		)
		const vha_crada_client_agreement: Data<Document> | undefined = data.find(
			(document: Data<Document>) =>
				document.attributes.step == DOCUMENT_STEPS.vha_crada_client_agreement,
		)
		const poa_agreement: Data<Document> | undefined = data.find(
			(document: Data<Document>) => document.attributes.step === DOCUMENT_STEPS.poa_agreement,
		)

		commit('setContract', client_agreement?.attributes)
		commit('setContractPOA', poa_agreement?.attributes)
		commit('setContractSti', sti_client_agreement?.attributes)
		commit('setContractVhaCrada', vha_crada_client_agreement?.attributes)
	},
}

const getters: GetterTree<State, RootState> = {}

const module: Module<State, RootState> = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

export default module
