import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store'
import axios from 'axios'
import { AxiosList, Meta, RequestParams } from '@/modules/common/apiConfig'
import { Order, OrderAttributes } from '@/modules/orders/types/orderTypes'

export type State = {
	orders: Order[]
	ordersMeta: Meta
	ordersLoading: boolean
}

export const state = (): State => ({
	orders: [],
	ordersMeta: {
		from: 1,
		to: 1,
		current_page: 1,
	},
	ordersLoading: false,
})

const mutations: MutationTree<State> = {
	setOrders(state: State, orders: Order[]) {
		state.orders = orders
	},
	setOrdersMeta(state: State, ordersMeta: Meta) {
		state.ordersMeta = ordersMeta
	},
	setOrdersLoading(state: State, loading: boolean) {
		state.ordersLoading = loading
	},
}

const actions: ActionTree<State, RootState> = {
	async getOrders(
		{ commit, state },
		params = {
			related: 'items[title|quantity|created_at].product',
		} as RequestParams,
	): Promise<Order[] | void> {
		try {
			commit('setOrdersLoading', true)
			let ordersData: AxiosList<OrderAttributes> = await axios.get('/restify/orders', {
				params,
			})

			let { data, meta } = ordersData

			if (params?.page > 1) {
				data = state.orders.concat(data)
			}

			commit('setOrders', data)
			commit('setOrdersMeta', meta)

			return data
		} catch (err: any) {
			if (err.handled) {
				return
			}
			throw err
		} finally {
			commit('setOrdersLoading', false)
		}
	},
}

const getters: GetterTree<State, RootState> = {}

const module: Module<State, RootState> = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

export default module
