import { App } from 'vue'
import { success } from '@/components/common/NotificationPlugin'

async function copyToClipboard(value: string, message = 'Text copied to clipboard.') {
	const el = document.createElement('textarea')

	el.value = value

	document.body.appendChild(el)
	el.select()
	await window.navigator.clipboard.writeText(value)
	document.body.removeChild(el)
	success(message)
}

export default {
	install(Vue: App) {
		Vue.config.globalProperties.$copyToClipboard = copyToClipboard
	},
}
