import { App } from 'vue'

const CURRENCIES = {
	USD: 'usd',
}

export type FormatOptions = {
	currency?: string
	locale?: string
	currencyCode?: string
	minimumFractionDigits?: number
	maximumFractionDigits?: number
	minimumIntegerDigits?: number
	currencyDisplay?: boolean
}

function isFloat(n: number) {
	return n === +n && n !== (n | 0)
}

export function formatPrice(value: number | null, options: FormatOptions = {}) {
	if (value === null || value === undefined) {
		return '- -'
	}

	let formatter
	let locale = options.locale || 'en-US'
	const minimumFractionDigits = isFloat(value) ? options.minimumFractionDigits || 2 : 0

	if (options.currencyDisplay === false) {
		formatter = new Intl.NumberFormat(locale, {
			maximumFractionDigits: options.maximumFractionDigits || 0,
			minimumFractionDigits: options.minimumFractionDigits || 0,
		})
	} else {
		formatter = new Intl.NumberFormat(locale, {
			style: 'currency',
			currency: options.currencyCode || CURRENCIES.USD,
			maximumFractionDigits: options.maximumFractionDigits || 2,
			minimumFractionDigits: minimumFractionDigits,
		})
	}

	return formatter.format(value)
}

export default {
	install(Vue: App) {
		Vue.config.globalProperties.$formatPrice = formatPrice
	},
}
