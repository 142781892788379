import { Item, StorageStatus } from '@/modules/items/types/itemTypes'
import { ConsideringFreezingOptions, ResultDocument } from '@/modules/items/types/resultsTypes'
import { KitRegistration } from '@/modules/kitRegistrations/types/kitRegistrationTypes'
import { StiResult } from '@/modules/items/types/stiResultsTypes'
import { isArray } from 'lodash-es'
import { State } from '@/modules/items/store/itemsModule'
import { PackagesUuid } from '@/modules/checkout/enum/packagesUuid'
import store from '@/store'
import { ProductsUuid } from '@/modules/checkout/enum/productsUuid'
import useTesting from '@/modules/testing/composables/useTesting'
import { dummyAnalysisWithStoredVials } from '@/modules/testing/data/analysisWithStoredVials'

const { setStoredVials } = useTesting()

export const itemsTestingMutations = {
	updateResultsValue(
		state: State,
		{ item, key, value }: { item: Item; key: string; value: number },
	) {
		const analysis = state.items.find(x => x.id === item.id)

		const resultDocument = (analysis as Item)?.relationships?.resultDocuments?.[0]

		resultDocument.attributes[key] = value
	},
	updateItemValue(state: State, { item, key, value }: { item: Item; key: string; value: number }) {
		const analysis = state.items.find(x => x.id === item.id) as any

		analysis.attributes[key] = value
	},
	setBoughtStorage(state: State, { item, value }: { item: Item; value: boolean }) {
		const analysis = state.items.find(x => x.id === item.id) as Item

		const cryogenicAddons = value ? [{}] : []

		analysis.relationships = {
			...(analysis.relationships || {}),
			cryogenicAddons,
		}
	},
	setStorageStatus(state: State, { item, value }: { item: Item; value: StorageStatus }) {
		const analysis = state.items.find(x => x.id === item.id) as Item

		analysis.attributes.storage_status!.status = value
	},
	setConsideringFreezing(
		state: State,
		{ item, value }: { item: Item; value: ConsideringFreezingOptions },
	) {
		const analysis = state.items.find(x => x.id === item.id) as Item
		analysis.attributes.considering_freezing = value
	},
	setItemStep(state: State, { item, value }: { item: Item; value: number }) {
		let analysis = state.items.find((i: Item) => i.attributes.uuid === item.attributes.uuid)
		analysis!.attributes.status.step = value
	},
	setItemKitRegistration(
		state: State,
		{ item, kitRegistration }: { item: Item; kitRegistration: KitRegistration },
	) {
		let analysis = state.items.find((i: Item) => i.attributes.uuid === item.attributes.uuid)
		analysis!.relationships!.kitRegistration = kitRegistration
	},
	setResultDocuments(
		state: State,
		{ item, resultDocuments }: { item: Item; resultDocuments: ResultDocument[] },
	) {
		let analysis = state.items.find((i: Item) => i.attributes.uuid === item.attributes.uuid)
		analysis!.relationships!.resultDocuments = resultDocuments
	},
	setStiResultDocuments(state: State, { item, stiResults }: { item: Item; stiResults: StiResult }) {
		let analysis = state.items.find((i: Item) => i.attributes.uuid === item.attributes.uuid)
		analysis!.relationships!.stiResult = stiResults
	},
	addItems(state: State, item: Item | Item[]) {
		if (!item) {
			return
		}
		if (isArray(item)) {
			state.items.push(...item)
		} else {
			state.items.push(item)
		}
		state.itemsMeta.total = state.items.length
		handleCryogenics(state.items)
	},
	removeItems(state: State, item: Item) {
		if (!item) {
			return
		}

		state.items = filteredItems(state.items, item)
		state.itemsMeta.total = state.items.length

		handleResults(state.items)
		handleCryogenics(state.items)
	},
	setTesting(state: State, value: boolean) {
		state.testing = value
	},
}

function filteredItems(stateItems: Item[], item: Item) {
	return stateItems.filter(function (i: Item) {
		const packageUuid = i!.relationships?.package?.attributes?.uuid
		const productUuid = i!.relationships?.product?.attributes?.uuid

		if (packageUuid) {
			return (
				i!.relationships?.package?.attributes.uuid !== item!.relationships?.package?.attributes.uuid
			)
		}

		if (productUuid) {
			return (
				i!.relationships?.product?.attributes.uuid !== item!.relationships?.product?.attributes.uuid
			)
		}

		return i
	})
}

const analysisItemUuids = [
	PackagesUuid.TODAY_UUID,
	PackagesUuid.TODAY_PLUS_DNA_UUID,
	PackagesUuid.TOMORROW_UUID,
	PackagesUuid.FOREVER_UUID,
	PackagesUuid.ANALYSIS_PLUS_SUPPLEMENTS_UUID,
]

const cryogenicItemUuids = [
	PackagesUuid.TOMORROW_UUID,
	PackagesUuid.FOREVER_UUID,
	ProductsUuid.CRYO_MONTHLY_UUID,
	ProductsUuid.CRYO_YEARLY_UUID,
	ProductsUuid.CRYO_5_UUID,
]

function analysisItemsPresent(stateItems: Item[]) {
	return stateItems.some(item =>
		analysisItemUuids.includes(item!.relationships?.package?.attributes.uuid),
	)
}

function cryogenicItemsPresent(stateItems: Item[]) {
	return stateItems.some(item =>
		cryogenicItemUuids.includes(
			item!.relationships?.package?.attributes.uuid ||
				item!.relationships?.product?.attributes.uuid,
		),
	)
}

function handleResults(stateItems: Item[]) {
	if (!analysisItemsPresent(stateItems)) {
		store.commit('auth/updateClientValue', {
			key: 'has_results_ready',
			value: false,
		})
	}
}

function setCryo(value: boolean) {
	store.commit('cryogenics/setHasCryogenic', value)
	store.commit('auth/updateClientValue', {
		key: 'has_active_subscriptions',
		value: value,
	})
}

function setVials(stateItems: Item[]) {
	const mostExpensiveCryo = stateItems
		.filter((i: Item) => i!.attributes?.is_cryogenic)
		.sort((a: Item, b: Item): number => Number(b?.attributes?.price) - Number(a?.attributes?.price))

	dummyAnalysisWithStoredVials!.addons![0].title = mostExpensiveCryo?.[0]?.attributes.title
	dummyAnalysisWithStoredVials!.id!++

	setStoredVials([dummyAnalysisWithStoredVials])
}

function handleCryogenics(stateItems: Item[]) {
	if (cryogenicItemsPresent(stateItems) && analysisItemsPresent(stateItems)) {
		setVials(stateItems)
		setCryo(true)
	} else if (cryogenicItemsPresent(stateItems)) {
		setStoredVials([])
		setCryo(true)
	} else {
		setStoredVials([])
		setCryo(false)
	}
}
