import { Directive } from 'vue'
import { CustomHTMLElement, DirectiveBinding } from '@vue/runtime-core'

const clickOutsideDirective: Directive = {
	mounted: (el: CustomHTMLElement, binding: DirectiveBinding) => {
		el.clickOutsideEvent = (event: Event) => {
			const target = <HTMLElement>event.target

			if (!(el === event.target || el.contains(target))) {
				binding.value()
			}
		}
		document.body.addEventListener('click', el.clickOutsideEvent)
	},
	unmounted: (el: CustomHTMLElement) => {
		document.body.removeEventListener('click', el.clickOutsideEvent)
	},
}

export default clickOutsideDirective
