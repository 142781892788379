import { configure, defineRule } from 'vee-validate'
import AllRules from '@vee-validate/rules'
import { localize } from '@vee-validate/i18n'
import en from '@vee-validate/i18n/dist/locale/en.json'
import i18n from '@/i18n'
import { debouncedTrackEvent } from '@/modules/analytics/track'
import { TrackingEvents } from '@/modules/common/utils/trackingEvents'
import { DebounceResponse } from '@/modules/common/types/common'
import { debouncedEmailValidate } from '@/modules/checkout/helpers/checkoutUtils'

export default {
	install() {
		configure({
			generateMessage: localize({
				en,
			}),
		})
		Object.keys(AllRules).forEach(rule => {
			const validationRule = AllRules[rule]
			if (typeof validationRule === 'function') {
				defineRule(rule, validationRule)
			}
		})

		defineRule('email', (value: string) => {
			if (
				!value ||
				/(^$)|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
					value,
				)
			) {
				return true
			}
			return i18n.t('This field must be a valid email')
		})

		defineRule('url', (value: string) => {
			if (
				!/(^$)|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/.test(
					value,
				)
			) {
				return i18n.t('This field must be a valid url')
			}
			return true
		})

		defineRule('phoneNumber', (value: string, [countryIso2]: Array<string>) => {
			countryIso2 = countryIso2 || 'us'
			if (!window.intlTelInputUtils.isValidNumber(value, countryIso2)) {
				return i18n.t('Invalid phone number')
			}

			return true
		})

		defineRule('zeroBounce', async (value: string) => {
			const data: DebounceResponse = await debouncedEmailValidate(value)

			const isInValid =
				data?.isValid === false && localStorage.getItem('bypass_email_validation') !== 'true'

			if (isInValid) {
				const trackingEvent = TrackingEvents.EMAIL_VALIDATION_FAILED

				const event = {
					...trackingEvent,
					options: { ...trackingEvent.options, email: value },
				}

				await debouncedTrackEvent(event)

				return i18n.t('You have entered an invalid email address. Please try again')
			}

			return true
		})
	},
}
