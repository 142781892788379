import {
	ResultDocument,
	SectionStates,
	StorageStates,
	ConsideringFreezingOptions,
	ResultsSliderOptions,
} from '@/modules/items/types/resultsTypes'
import { WHORanges } from '@/modules/items/utils/resultsRanges'
import { formatPercent } from '@/plugins/formatPercentPlugin'
import { formatPrice } from '@/plugins/formatPricePlugin'
import { formatDate } from '@/modules/common/utils/dateUtils'
import { Item, StorageStatus } from '@/modules/items/types/itemTypes'
import store from '@/store'

// #region Commmon
export function getIsAzoospermic(resultDocument: ResultDocument): boolean {
	return resultDocument.is_azoospermic
}

export function getStorageState(item: Item): StorageStates {
	const boughtStorage = (item.relationships?.cryogenicAddons?.length || 0) > 0
	const considerFreezingAnswer: ConsideringFreezingOptions = (item.attributes
		?.considering_freezing || ConsideringFreezingOptions.Yes) as ConsideringFreezingOptions

	const isAnalysisOnly = [ConsideringFreezingOptions.No].includes(considerFreezingAnswer)

	if (boughtStorage || isAnalysisOnly) {
		return StorageStates.BoughtStorageOrAnalysisOnly
	}

	return StorageStates.ConsideringStorage
}

const LOW_CONCENTRATION_THRESHOLD = 1

export function getHasLowConcentration(resultDocument: ResultDocument): boolean {
	return getConcentrationValue(resultDocument) < LOW_CONCENTRATION_THRESHOLD
}

// #endregion Common

// #region Total count
export function getTcValue(resultDocument: ResultDocument): number {
	return resultDocument.total_count || 0
}

export const tcSliderOptions: ResultsSliderOptions = {
	sliderRanges: WHORanges.totalCount,
	customNormalRangePercent: 50,
	valueFormatter: (value: number) => {
		const formattedValue = formatPrice(value, {
			currencyDisplay: false,
			minimumFractionDigits: 0,
			maximumFractionDigits: 2,
		})
		return `${formattedValue}M`
	},
}

export function getIsNormalTc(resultDocument: ResultDocument): boolean {
	return getTcValue(resultDocument) >= WHORanges.totalCount.normal
}

export function getTcTopPercentValue(resultDocument: ResultDocument): number {
	return 0
}

export function getTcState(resultDocument: ResultDocument): SectionStates {
	if (getIsAzoospermic(resultDocument)) {
		return SectionStates.Azoospermic
	}

	if (getIsNormalTc(resultDocument)) {
		return SectionStates.Normal
	}

	return SectionStates.Low
}

// #endregion Total count

// #region TMC

export const tmcSliderOptions: ResultsSliderOptions = {
	sliderRanges: WHORanges.totalMotileCount,
	customNormalRangePercent: 50,
	valueFormatter: (value: number) => {
		const formattedValue = formatPrice(value, {
			currencyDisplay: false,
			minimumFractionDigits: 0,
			maximumFractionDigits: 2,
		})
		return `${formattedValue}M`
	},
}

export function getTmcValue(resultDocument: ResultDocument): number {
	if (getIsAzoospermic(resultDocument)) {
		return 0
	}
	return resultDocument.total_motile_sperm || 0
}

export function getIsNormalTmc(resultDocument: ResultDocument): boolean {
	return getTmcValue(resultDocument) >= WHORanges.totalMotileCount.normal
}

export function getTmcTopPercentValue(resultDocument: ResultDocument): number {
	// At the moment we don't have percentiles defined for TMC - might add in the futre
	return 0
}

export function getTmcState(resultDocument: ResultDocument): SectionStates {
	if (getIsAzoospermic(resultDocument)) {
		return SectionStates.Azoospermic
	}

	if (getIsNormalTmc(resultDocument)) {
		return SectionStates.Normal
	}

	return SectionStates.Low
}

// #endregion TMC

// #region Volume

export const volumeSliderOptions: ResultsSliderOptions = {
	sliderRanges: WHORanges.volume,
	customNormalRangePercent: 50,
	valueFormatter: (value: number) => {
		const formattedValue = formatPrice(value, {
			currencyDisplay: false,
			minimumFractionDigits: 0,
			maximumFractionDigits: 2,
		})
		return `${formattedValue}mL`
	},
}

export function getVolumeValue(resultDocument: ResultDocument): number {
	// if (getIsAzoospermic(resultDocument)) {
	//   return 0
	// }
	return resultDocument.volume || 0
}

function getIsNormalVolume(resultDocument: ResultDocument): boolean {
	return getVolumeValue(resultDocument) >= WHORanges.volume.normal
}

export function getVolumeTopPercentValue(resultDocument: ResultDocument): number {
	const volume = getVolumeValue(resultDocument)

	for (const percentile of WHORanges.volume.percentiles || []) {
		if (volume >= percentile.min) {
			return percentile.topPercent
		}
	}

	return 0
}

export function getVolumeState(resultDocument: ResultDocument): SectionStates {
	// if (getIsAzoospermic(resultDocument)) {
	//   return SectionStates.Azoospermic
	// }

	if (getIsNormalVolume(resultDocument)) {
		return SectionStates.Normal
	}

	return SectionStates.Low
}

// #endregion Volume

// #region Concentration

export const concentrationSliderOptions: ResultsSliderOptions = {
	sliderRanges: WHORanges.concentration,
	customNormalRangePercent: 50,
	valueFormatter: (value: number) => {
		const formattedValue = formatPrice(value, {
			currencyDisplay: false,
			minimumFractionDigits: 0,
			maximumFractionDigits: 2,
		})
		return `${formattedValue}M/ml`
	},
}

export function getConcentrationValue(resultDocument: ResultDocument): number {
	if (getIsAzoospermic(resultDocument)) {
		return 0
	}
	return resultDocument.actual_ejaculate_concentration ?? (resultDocument.concentration || 0)
}

function getIsNormalConcentration(resultDocument: ResultDocument): boolean {
	return getConcentrationValue(resultDocument) >= WHORanges.concentration.normal
}

export function getConcentrationTopPercentValue(resultDocument: ResultDocument): number {
	const concentration = getConcentrationValue(resultDocument)

	for (const percentile of WHORanges.concentration.percentiles || []) {
		if (concentration >= percentile.min) {
			return percentile.topPercent
		}
	}

	return 0
}

export function getConcentrationState(resultDocument: ResultDocument): SectionStates {
	if (getIsAzoospermic(resultDocument)) {
		return SectionStates.Azoospermic
	}

	if (getIsNormalConcentration(resultDocument)) {
		return SectionStates.Normal
	}

	return SectionStates.Low
}

// #endregion Concentration

// #region Motility

export const motilitySliderOptions: ResultsSliderOptions = {
	sliderRanges: WHORanges.motility,
	customNormalRangePercent: 50,
	valueFormatter: (value: number) => {
		return formatPercent(value, {
			minimumFractionDigits: 1,
			maximumFractionDigits: 2,
		})
	},
}

export function getMotilityValue(resultDocument: ResultDocument): number {
	if (getIsAzoospermic(resultDocument)) {
		return 0
	}
	return resultDocument.total_motility || 0
}

function getIsNormalMotility(resultDocument: ResultDocument): boolean {
	return getMotilityValue(resultDocument) >= WHORanges.motility.normal
}

export function getMotilityTopPercentValue(resultDocument: ResultDocument): number {
	const motility = getMotilityValue(resultDocument)

	for (const percentile of WHORanges.motility.percentiles || []) {
		if (motility >= percentile.min) {
			return percentile.topPercent
		}
	}

	return 0
}

export function getMotilityState(resultDocument: ResultDocument): SectionStates {
	if (getIsAzoospermic(resultDocument)) {
		return SectionStates.Azoospermic
	}

	if (getIsNormalMotility(resultDocument)) {
		return SectionStates.Normal
	}

	return SectionStates.Low
}

// #endregion Motility

// #region Morphology

export const morphologySliderOptions: ResultsSliderOptions = {
	sliderRanges: WHORanges.morphology,
	customNormalRangePercent: 50,
	valueFormatter: (value: number) => {
		return formatPercent(value, {
			minimumFractionDigits: 1,
			maximumFractionDigits: 2,
		})
	},
}

export function getMorphologyValue(resultDocument: ResultDocument): number {
	if (getIsAzoospermic(resultDocument)) {
		return 0
	}
	return resultDocument.normal
}

function getIsNormalMorphology(resultDocument: ResultDocument): boolean {
	return getMorphologyValue(resultDocument) >= WHORanges.morphology.normal
}

export function getMorphologyTopPercentValue(resultDocument: ResultDocument): number {
	// At the moment we don't have percentiles defined for Morphology - might add in the futre
	return 0
}

export function getMorphologyState(resultDocument: ResultDocument): SectionStates {
	if (getIsAzoospermic(resultDocument)) {
		return SectionStates.Azoospermic
	}

	if (getHasLowConcentration(resultDocument)) {
		return SectionStates.LowConcentration
	}

	if (getIsNormalMorphology(resultDocument)) {
		return SectionStates.Normal
	}

	return SectionStates.Low
}

// #endregion Morphology

// #region DNA Fragmentation

export const dnaFragmentationSliderOptions: ResultsSliderOptions = {
	sliderRanges: WHORanges.dnaFrag,
	reverseGradient: true,
	valueFormatter: (value: number) => {
		return formatPercent(value, {
			minimumFractionDigits: 1,
			maximumFractionDigits: 2,
		})
	},
}

export function getDnaFragmentationValue(resultDocument: ResultDocument): number {
	if (getIsAzoospermic(resultDocument)) {
		return 0
	}
	return resultDocument.dna_fragmentation || 0
}

function getIsNormalDnaFragmentation(resultDocument: ResultDocument): boolean {
	return getDnaFragmentationValue(resultDocument) <= WHORanges.dnaFrag.normal
}

export function getDnaFragmentationTopPercentValue(resultDocument: ResultDocument): number {
	// At the moment we don't have percentiles defined for DnaFragmentation - might add in the futre
	return 0
}

export function getDnaFragmentationState(resultDocument: ResultDocument): SectionStates {
	if (getIsAzoospermic(resultDocument)) {
		return SectionStates.Azoospermic
	}

	if (getHasLowConcentration(resultDocument)) {
		return SectionStates.LowConcentration
	}

	if (getIsNormalDnaFragmentation(resultDocument)) {
		return SectionStates.Normal
	}

	return SectionStates.High
}

// #endregion DNA Fragmentation

// #region Cryogenic Storage
export function getFreezeByDate(daysToSecure: number): string {
	if (!daysToSecure) {
		return ''
	}

	let freezeBy = new Date()
	freezeBy.setDate(freezeBy.getDate() + daysToSecure)

	return formatDate(freezeBy, 'MMM dd, yyyy')
}

export function getPostThawMotilityValue(resultDocument: ResultDocument): number {
	return resultDocument.post_thaw_motility || 0
}

export function getIsNormalPostThawMotility(resultDocument: ResultDocument): boolean {
	return getPostThawMotilityValue(resultDocument) >= WHORanges.post_thaw_motility.normal
}

export function getIsWithdrawnStatus(item: Item): boolean {
	return item.attributes.storage_status?.status === StorageStatus.Withdrawn
}

export function getCryoStorageState(item: Item): StorageStates {
	const boughtStorage = (item.relationships?.cryogenicAddons?.length || 0) > 0
	const user = store.state.auth.user
	const hasUnmatchedSubscriptions = user.has_authorized_user_role
		? user.parent_user.client.has_unmatched_subscriptions
		: user.client?.attributes?.has_unmatched_subscriptions

	if (hasUnmatchedSubscriptions) {
		return StorageStates.BoughtStorageAndCryoNotMatched
	}

	if (boughtStorage) {
		return StorageStates.BoughtStorage
	}

	if (item.attributes?.considering_freezing !== ConsideringFreezingOptions.No) {
		return StorageStates.ConsideringStorage
	}

	return StorageStates.NotConsideringStorage
}

export function getCryogenicState(resultDocument: ResultDocument, analysis: Item): SectionStates {
	if (getIsAzoospermic(resultDocument)) {
		return SectionStates.Azoospermic
	}

	if (!getIsNormalPostThawMotility(resultDocument)) {
		return SectionStates.LowPostThawMotility
	}

	if (getIsWithdrawnStatus(analysis)) {
		return SectionStates.Withdrawn
	}

	return SectionStates.Normal
}
// #endregion Cryogenic Storage
