import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store'
import axios from 'axios'
import {
	KitRegistrationMeta,
	VerifyDateOfBirthParams,
	VerifyKitParams,
} from '@/modules/kitRegistrations/types/kitRegistrationTypes'

export type State = {
	meta: KitRegistrationMeta | null
}

export const state = (): State => ({
	meta: null,
})

const mutations: MutationTree<State> = {
	setKitRegistrationPageMeta(state: State, meta: KitRegistrationMeta) {
		state.meta = meta
	},
}

const actions: ActionTree<State, RootState> = {
	async verifyKit({ commit }, requestParams: VerifyKitParams) {
		await axios.post(
			`/restify/items/actions?action=validate`,
			{},
			{
				params: requestParams,
			},
		)
	},
	async verifyDateOfBirth({ commit, dispatch }, requestParams: VerifyDateOfBirthParams) {
		const { data } = await axios.post(
			`/kit-registrations/verify-client-dob/${requestParams.identifier}`,
			requestParams,
		)

		commit('auth/setUseOldAuthentication', true, { root: true })

		dispatch('auth/loginWithToken', { token: data.token, redirect: false }, { root: true })

		return data
	},
	async getKitRegistrationPageMeta({ commit, dispatch }, requestParams: {}) {
		const { meta } = await axios.get(`/restify/kit-registrations`, requestParams)
		commit('setKitRegistrationPageMeta', meta)
	},
}

const getters: GetterTree<State, RootState> = {}

const module: Module<State, RootState> = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

export default module
