<template>
	<div
		class="notification-item"
		:class="[verticalAlign, horizontalAlign, alertType]"
		:style="customPosition"
	>
		<transition
			appear
			appear-active-class="transform ease-out duration-300 transition"
			appear-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
			appear-to-class="translate-y-0 opacity-100 sm:translate-x-0"
			leave-active-class="transition ease-in duration-100"
			leave-class="opacity-100"
			leave-to-class="opacity-0"
		>
			<div
				v-show="showNotification"
				class="max-w-sm bg-white dark:bg-gray-900 shadow-lg rounded-lg pointer-events-auto"
			>
				<div
					class="rounded-lg shadow-xs overflow-hidden relative"
					@click="tryClose"
				>
					<div class="p-4">
						<div class="flex items-start">
							<div
								class="h-6 w-6 flex justify-center items-center rounded-full"
								:class="{
									'bg-success-100': type === 'success',
									'bg-evergreen-100': type === 'info',
									'bg-error-100': type === 'error',
									'bg-gold-100': type === 'warning',
								}"
							>
								<svg
									class="h-4 w-4"
									:class="{
										'text-success-500': type === 'success',
										'text-evergreen-900': type === 'info',
										'text-error-500': type === 'error',
										'text-gold-900': type === 'warning',
									}"
									stroke="currentColor"
									fill="none"
									viewBox="0 0 24 24"
								>
									<path
										v-if="type === 'error' || type === 'warning'"
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
									></path>
									<path
										v-else
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M5 13l4 4L19 7"
									></path>
								</svg>
							</div>
							<div class="ml-3 w-0 flex-1 pt-0.5">
								<div
									v-if="message"
									v-html="message"
									class="text-sm leading-5 text-gray-900 dark:text-white"
								></div>
							</div>
							<div class="ml-4 flex-shrink-0 flex">
								<BaseButton
									v-if="showClose"
									:icon="Icons.X"
									icon-only
									round
									size="sm"
									variant="tertiary"
									class="absolute right-0 top-0 mr-2 mt-2"
									@click="tryClose"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>
<script lang="ts">
import { NotificationType } from './index'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
	name: 'Notification',
	props: {
		message: [String, Object],
		title: String,
		verticalAlign: {
			type: String,
			default: 'top',
			validator: (value: string) => {
				let acceptedValues = ['top', 'bottom']
				return acceptedValues.indexOf(value) !== -1
			},
		},
		horizontalAlign: {
			type: String,
			default: 'right',
			validator: (value: string) => {
				let acceptedValues = ['left', 'center', 'right']
				return acceptedValues.indexOf(value) !== -1
			},
		},
		type: {
			type: String as PropType<NotificationType>,
			default: 'info',
		},
		timeout: {
			type: Number,
			default: 5000,
			validator: (value: number) => {
				return value >= 0
			},
		},
		timestamp: {
			type: [Date, Number],
			default: () => new Date(),
		},
		component: {
			type: [Object, Function],
		},
		showClose: {
			type: Boolean,
			default: true,
		},
		closeOnClick: {
			type: Boolean,
			default: true,
		},
		clickHandler: Function,
	},
	emits: ['close'],
	data() {
		return {
			elmHeight: 0,
			showNotification: true,
		}
	},
	computed: {
		alertType() {
			return `notification-${this.type}`
		},
		customPosition() {
			let initialMargin = 20
			let alertHeight = this.elmHeight + 10
			let sameAlertsCount = this.$notifications.state.filter(alert => {
				return (
					alert.horizontalAlign === this.horizontalAlign &&
					alert.verticalAlign === this.verticalAlign &&
					alert.timestamp &&
					alert.timestamp <= this.timestamp
				)
			}).length
			if (this.$notifications.settings.overlap) {
				sameAlertsCount = 1
			}
			let pixels = (sameAlertsCount - 1) * alertHeight + initialMargin
			let styles: any = {}
			if (this.verticalAlign === 'top') {
				styles.top = `${pixels}px`
			} else {
				styles.bottom = `${pixels}px`
			}
			return styles
		},
	},
	methods: {
		close() {
			this.showNotification = false
			// We need this for leave transitions to work
			setTimeout(() => {
				this.$emit('close', this.timestamp)
			}, 500)
		},
		tryClose(evt: Event) {
			if (this.clickHandler) {
				this.clickHandler(evt, this)
			}
			if (this.closeOnClick) {
				this.close()
			}
		},
	},
	mounted() {
		this.elmHeight = this.$el.clientHeight
		if (this.timeout) {
			setTimeout(this.close, this.timeout)
		}
	},
})
</script>
<style lang="scss">
.notifications .notification-item {
	position: fixed;
	z-index: 10000;
	width: 400px;
	cursor: pointer;

	&.center {
		left: 0;
		right: 0;
		margin: 0 auto;
	}

	&.left {
		left: 20px;
	}

	&.right {
		right: 20px;
	}
}
</style>
