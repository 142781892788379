<template>
	<BaseButton
		v-bind="$attrs"
		:loading="loading"
		@click="getLink"
	>
		<slot>{{ $t('Schedule an appointment') }}</slot>
	</BaseButton>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	props: {
		source: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
		}
	},
	methods: {
		async getLink(): Promise<void> {
			try {
				this.loading = true
				let requestData = {
					params: {
						client: this.$client?.number,
						source: this.source,
					},
				}

				await this.$store.dispatch('telehealth/redirectToCalendly', requestData)
			} finally {
				this.loading = false
			}
		},
	},
})
</script>
