import { StiResult, StiStates, StiAnalysisResultTypes } from '@/modules/items/types/stiResultsTypes'
import { Item } from '@/modules/items/types/itemTypes'
import { ConsideringFreezingOptions, StorageStates } from '@/modules/items/types/resultsTypes'
import useClient from '@/composables/useClient'
import useUser from '@/composables/useUser'

export function getStiStorageState(item: Item): StorageStates {
	const client = useClient()
	const user = useUser()

	if (user.has_authorized_user_role && user?.parent_user?.client.has_cryogenic) {
		return StorageStates.BoughtStorage
	}

	if (client?.has_cryogenic) {
		return StorageStates.BoughtStorage
	}

	// TODO: Check if we should update the logic here to look at last analysis or some analysis in the same package
	// Otherwise will always default to yes
	const considerFreezingAnswer: ConsideringFreezingOptions = (item.attributes
		?.considering_freezing || ConsideringFreezingOptions.Yes) as ConsideringFreezingOptions

	if (considerFreezingAnswer === ConsideringFreezingOptions.No) {
		return StorageStates.NotConsideringStorage
	}

	return StorageStates.ConsideringStorage
}

export function getStiState(item: Item): StiStates {
	const stiResults = item.relationships?.stiResult?.attributes?.results || ([] as StiResult[])

	const hasInconclusiveResults = stiResults.some(
		(stiResult: StiResult) => stiResult.readable === StiAnalysisResultTypes.Inconclusive,
	)
	const hasPositiveResults = stiResults.some(
		(stiResult: StiResult) => stiResult.readable === StiAnalysisResultTypes.Positive,
	)

	if (hasInconclusiveResults && hasPositiveResults) {
		return StiStates.SomeInconclusiveAndSomePositive
	}

	if (hasInconclusiveResults) {
		return StiStates.SomeInconclusive
	}

	if (hasPositiveResults) {
		return StiStates.SomePositive
	}

	return StiStates.AllTestsNegative
}
