// @ts-nocheck
export const userNamesMixin = {
	computed: {
		userPreferredFullName() {
			if (this.$user.preferred_first_name) {
				return `${this.$user.preferred_first_name} ${this.$user.last_name}`
			}

			return this.$user.name
		},
	},
}
