<template>
	<BaseDialog v-bind="$attrs">
		<div class="flex flex-col items-center text-center">
			<img
				src="/img/consultations.png"
				:alt="$t('Consultations')"
			/>
			<h3
				class="my-8"
				:class="{
					'lg:mt-16': !oldResult,
				}"
			>
				{{ title }}
			</h3>
			<p
				v-html="subtitle"
				class="text-md"
			/>
			<div
				class="mt-8 w-full md:w-auto"
				:class="{
					'lg:mt-12': oldResult,
					'lg:mt-16': !oldResult,
				}"
			>
				<template v-if="!hasOnlyStiAndSupplements">
					<BaseButton
						v-if="!$client?.has_results_ready"
						size="lg"
						@click="closeDialog"
					>
						{{ $t('Close') }}
					</BaseButton>

					<BaseButton
						v-else-if="!$client?.has_active_fertility_consultation && !beforeLaunchingPaidProduct"
						:href="fertilityPackageCheckoutLink"
						target="_blank"
						tag="a"
						size="lg"
						@click="onBookNowClick"
					>
						{{ $t('Book now for $150') }}
					</BaseButton>

					<TelehealthButton
						source="ClientDashNavBar"
						v-else
						size="lg"
						variant="primary"
						icon="calendar"
						icon-position="left"
						@click="onScheduleClick"
					>
						{{ $t('Schedule my consultation') }}
					</TelehealthButton>
				</template>
				<div
					v-else
					class="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0"
				>
					<BaseButton
						:href="orderLink"
						rel="noopener"
						target="_blank"
						tag="a"
						size="lg"
						variant="primary"
					>
						{{ $t('Order semen analysis') }}
					</BaseButton>
					<BaseButton
						@click="closeDialog"
						size="lg"
						variant="secondary"
					>
						{{ $t('Maybe later') }}
					</BaseButton>
				</div>
			</div>
		</div>
	</BaseDialog>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { FERTILITY_PACKAGE_CHECKOUT_LINK, ORDER_LINK } from '@/modules/common/utils/linkUtils'
import { Item, ItemAttributes } from '@/modules/items/types/itemTypes'
import { trackSegment } from '@/modules/analytics/track'
import { TrackingEvents } from '@/modules/common/utils/trackingEvents'
import { PackagesUuid } from '@/modules/checkout/enum/packagesUuid'
import { trackProductAddedToCart } from '@/modules/checkout/helpers/checkoutUtils'
import { ORDER_LINK_ONLY } from '@/modules/common/utils/linkUtils'
import { ResultDocument } from '@/modules/items/types/resultsTypes'
import { daysAgo } from '@/modules/common/utils/dateUtils'

const TELEHEALTH_PAID_PRODUCT_LAUNCH_DATE = new Date('2022-07-07')

const DAYS_AFTER_RESULT_IS_OLD = 90

export default defineComponent({
	name: 'ConsultationsDialog',
	data() {
		return {
			orderLink: ORDER_LINK,
			fertilityPackageCheckoutLink: FERTILITY_PACKAGE_CHECKOUT_LINK,
			ORDER_LINK_ONLY,
		}
	},
	computed: {
		items(): Item[] {
			return this.$store.state.items.items
		},
		hasOnlyStiAndSupplements(): boolean {
			return !this.items.some(
				(item: Item) => !item.attributes?.is_sti && !item.attributes?.is_supplement,
			)
		},
		title(): string {
			if (this.oldResult) {
				return this.$t('Get up-to-date results before speaking to a specialist')
			}

			if (this.hasOnlyStiAndSupplements) {
				return this.$t('Talk about your results with a Legacy fertility specialist')
			}

			if (!this.$client?.has_results_ready) {
				return this.$t('When your results are ready, schedule a fertility consultation.')
			}

			if (!this.$client?.has_active_fertility_consultation && !this.beforeLaunchingPaidProduct) {
				return this.$t('Schedule a consultation with a Legacy fertility specialist')
			}

			if (this.$client?.has_active_fertility_consultation && !this.beforeLaunchingPaidProduct) {
				return this.$t('It’s time to schedule your fertility consultation')
			}

			return this.$t('Schedule a free virtual appointment with a Legacy fertility specialist')
		},
		subtitle(): string {
			if (this.oldResult) {
				return this.$t('You have brand new sperm every 2–3 months.')
			}

			if (this.hasOnlyStiAndSupplements) {
				return this.$t(
					'Complimentary calls with Legacy specialists are only available to clients that have purchased semen analysis kits.',
				)
			}

			if (!this.$client?.has_results_ready) {
				return this.$t(
					'Once you have received your results, return here to book a fertility consultation with a Legacy fertility specialist.',
				)
			}

			if (!this.$client?.has_active_fertility_consultation && !this.beforeLaunchingPaidProduct) {
				return this.$t('In this 30-minute virtual consultation, a male fertility specialist will:')
			}

			if (this.$client?.has_active_fertility_consultation && !this.beforeLaunchingPaidProduct) {
				return this.$t(
					'Click below to book your consultation with a male fertility expert at Legacy.',
				)
			}

			return this.$t(
				'Book a virtual appointment with one of our male fertility clinicians to discuss your results, your family plans, and the next steps for you.',
			)
		},
		latestAnalysis(): ItemAttributes {
			return this.$client.latest_analysis
		},
		beforeLaunchingPaidProduct(): boolean {
			return new Date(this.latestAnalysis.created_at) < TELEHEALTH_PAID_PRODUCT_LAUNCH_DATE
		},
		latestReadyResult(): ResultDocument {
			return this.$user?.client?.relationships?.latest_ready_result
		},
		oldResult(): boolean {
			return new Date(this.latestReadyResult?.ready_at) < daysAgo(DAYS_AFTER_RESULT_IS_OLD)
		},
	},
	methods: {
		closeDialog(): void {
			this.$emit('update:modelValue', false)
		},
		onBookNowClick() {
			trackProductAddedToCart(
				PackagesUuid.FERTILITY_CONSULTATION_UUID,
				TrackingEvents.PRODUCT_ADDED.CONSULTATION_MODAL.location,
			)
			this.closeDialog()
		},
		onScheduleClick() {
			trackSegment(TrackingEvents.SCHEDULE_CONSULTATION.CONSULTATION_MODAL)
		},
	},
})
</script>
