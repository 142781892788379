export enum PackagesUuid {
	TODAY_UUID = '7aa649a6-8d21-4909-8073-cd5592002f54',
	TOMORROW_UUID = '472bbfbf-037a-41a5-a05a-31bd99e5827b',
	FOREVER_UUID = '2913c2bd-3b3c-4eec-a72a-719a15447b89',
	NSW_UUID = '5ee176cc-ef9a-44c2-b54a-235d085b5a8a',
	KINDBODY = '631dc2fb-3752-43a6-9f87-b49a90a66cb5',
	KINDBODY_ORLANDO = '801bb74d-7762-439a-aaf1-b098031aa2db',
	INSURANCE_UUID = '227e6f61-d91c-4105-92b0-48302a36d316',
	MALE_FERTILITY_SUPPLEMENT = '5939abf9-a3a5-4103-9591-06106cbe0de1',
	COQ10_UUID = 'd394cf17-ac2c-435b-9f27-6d203d7d3db5',
	BLACK_FRIDAY_2021 = '57ce4000-b5c2-49e3-ab8b-c244d0514bb1',
	FERTILITY_CONSULTATION_UUID = '8e2c4e5c-67af-43e6-b160-faeb4ec015dd',
	VHA_CRADA_UUID = 'c1d8ed68-0051-4bf9-9a14-f1dec3909932',
	DNA_FRAG_UUID = '276972e6-38b1-4c30-9234-fabaad52bf67',
	STI_UUID = '2c92b816-f617-4216-b428-48befb962be1',
	TODAY_PLUS_DNA_UUID = '7e39a5fe-5549-428a-ba15-b832472279d1',
	ANALYSIS_PLUS_SUPPLEMENTS_UUID = '23f27ea2-c0c1-4410-971a-2acaf3817149',
}
