import { App } from 'vue'
import {
	daysAgo,
	formatDate,
	formatDateTimezone,
	getTimezone,
} from '@/modules/common/utils/dateUtils'

export default {
	install(Vue: App) {
		Vue.config.globalProperties.$formatDate = formatDate
		Vue.config.globalProperties.$formatDateTimezone = formatDateTimezone
		Vue.config.globalProperties.$getTimezone = getTimezone
		Vue.config.globalProperties.$daysAgo = daysAgo
	},
}
