import en from 'date-fns/locale/en-US'
import { parseISO, format, subDays } from 'date-fns'
import { zonedTimeToUtc, utcToZonedTime, format as formatWithTz } from 'date-fns-tz'

export const DEFAULT_DATE_FORMAT = 'MMM. dd, yyyy'

export const UTC_TIMEZONE = 'UTC'

export function formatDate(date: Date | string, dateFormat = DEFAULT_DATE_FORMAT) {
	if (!date) {
		return '- -'
	}
	let dateToFormat: any = date
	if (typeof date === 'string') {
		// TEMP FIX
		if (date.endsWith('Z')) {
			date = date.substr(0, date.length - 1)
		}
		if (!date.includes('T')) {
			date += 'T00:00'
		}

		dateToFormat = parseISO(date)
	}
	if (!dateToFormat.getTime || isNaN(dateToFormat.getTime())) {
		return '- -'
	}

	return format(dateToFormat, dateFormat, { locale: en })
}

export function formatDateTimezone(
	date: Date | string,
	timezone = UTC_TIMEZONE,
	format: string | null = DEFAULT_DATE_FORMAT,
	isAlreadyUTC: boolean = false,
) {
	date = new Date(date)

	if (isNaN(date.getTime())) {
		return '- -'
	}

	const zonedTimeUtc = zonedTimeToUtc(date, UTC_TIMEZONE)
	const zonedTime = isAlreadyUTC
		? utcToZonedTime(date, timezone)
		: utcToZonedTime(zonedTimeUtc, timezone)

	if (!format) {
		return zonedTime
	}

	return formatWithTz(zonedTime, format)
}

export function getTimezone() {
	let timezone: string = ''
	try {
		timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
	} catch (err: any) {
		console.log('Error retrieving user timezone', err)
	}
	return timezone
}

export function daysAgo(days: number): Date {
	return subDays(new Date(), days)
}
