


export const layouts = {
'authLayout': () => import('/src/layouts/authLayout.vue'),
'defaultLayout': () => import('/src/layouts/defaultLayout.vue'),
'emptyLayout': () => import('/src/layouts/emptyLayout.vue'),
'onboardingLayout': () => import('/src/layouts/onboardingLayout.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      component: layouts[route.meta?.layout || 'defaultLayout'],
      children: [ {...route, path: ''} ],
    }
  })
}
