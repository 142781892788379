import { ItemAttributes } from '@/modules/items/types/itemTypes'

export const dummyAnalysisWithStoredVials: ItemAttributes = {
	id: 55,
	uuid: '73d55bb2-71d6-42fb-98b1-02075247dac0',
	package_id: 1,
	client_id: 26,
	lab_id: 2,
	is_addon: 0,
	title: 'Semen Analysis',
	// @ts-ignore
	price: 195,
	quantity: 1,
	number_of_kits: 1,
	// @ts-ignore
	po_number: 10000151,
	approved_at: '2022-08-22T14:19:01.000000Z',
	completed_at: '2022-09-13T13:57:22.000000Z',
	// @ts-ignore
	cancelled_at: null,
	created_at: '2022-06-06T14:18:25.000000Z',
	updated_at: '2022-09-13T13:57:22.000000Z',
	preferred_delivery_date: '08/30/2022',
	preferred_delivery_date_confirmed_at: '2022-08-22T14:19:01.000000Z',
	billing_address: null,
	shipping_address: {
		first_name: 'First',
		last_name: 'Last',
		full_name: 'First Last',
		address_1: 'Address 1B, ap.14',
		// @ts-ignore
		address_2: null,
		city: 'Cluj-Napoca',
		state: 'AL',
		postal_code: '12345',
		country: 'US',
		phone: '+1 231-231-2345',
		preferred_delivery_date: null,
		kit_delivery_type: 'staggered',
		pretty_address: 'Address 1B, ap.14, AL, 12345,US',
	},
	notes: null,
	dev_notes: null,
	considering_freezing: null,
	// @ts-ignore
	shipping_status: 'ready-for-shipping',
	shipping_status_updated_at: null,
	// @ts-ignore
	delivery_carrier: 'UPS',
	delivery_level: 'UPS 2 DAY AIR',
	destroy_requested_at: null,
	// @ts-ignore
	sent_at: null,
	used_at: null,
	sku: 'LMP-MSTR',
	not_viable: false,
	not_viable_reasons: null,
	not_viable_recoverable: false,
	duplicated_parent_id: null,
	duplicated_reason: null,
	data_migrated_at: null,
	generated_from_order_id: null,
	generated_from_order_at: null,
	deleted_at: null,
	onboarding_completed_at: '2022-08-22T14:19:01.000000Z',
	addon_parent_id: null,
	meta: null,
	human_delivery_date: 'August 30, 2022',
	human_delivery_address: 'Address 1B, ap.14, Cluj-Napoca, AL, 12345, US',
	product_type: 'analyses',
	human_renewal_date: '09/13/2023',
	kit_version: 'New v3 Kit',
	remaining_vials_count: 4,
	analysis_date: '07/12/2021',
	addons: [
		{
			// @ts-ignore
			id: 66,
			uuid: '27816a15-b663-4b9b-b2c6-416c3fd8f72a',
			package_id: null,
			client_id: 26,
			lab_id: null,
			is_addon: 0,
			title: 'Cryogenic Subscription Yearly',
			price: 145,
			quantity: 1,
			number_of_kits: 1,
			po_number: 10000162,
			approved_at: null,
			completed_at: null,
			cancelled_at: null,
			created_at: '2022-09-13T13:56:26.000000Z',
			updated_at: '2022-09-13T13:56:46.000000Z',
			preferred_delivery_date: null,
			preferred_delivery_date_confirmed_at: null,
			billing_address: null,
			shipping_address: {
				first_name: 'First',
				last_name: 'Last',
				full_name: 'First Last',
				address_1: 'Address 1B, ap.14',
				address_2: null,
				city: 'Cluj-Napoca',
				state: 'AL',
				postal_code: '12345',
				country: 'US',
				phone: '+1 231-231-2345',
				preferred_delivery_date: null,
				kit_delivery_type: 'staggered',
				pretty_address: 'Address 1B, ap.14, AL, 12345,US',
			},
			notes: null,
			dev_notes: null,
			considering_freezing: null,
			shipping_status: null,
			shipping_status_updated_at: null,
			delivery_carrier: null,
			delivery_level: 'FEDEX SECOND DAY',
			destroy_requested_at: null,
			sent_at: null,
			used_at: null,
			sku: 'in',
			not_viable: false,
			not_viable_reasons: null,
			not_viable_recoverable: false,
			duplicated_parent_id: null,
			duplicated_reason: null,
			data_migrated_at: null,
			generated_from_order_id: null,
			generated_from_order_at: null,
			deleted_at: null,
			onboarding_completed_at: null,
			addon_parent_id: 55,
			meta: null,
			human_delivery_date: 'September 14, 2022',
			human_delivery_address: 'Address 1B, ap.14, Cluj-Napoca, AL, 12345, US',
			product_type: 'cryo',
			human_renewal_date: '',
			kit_version: 'N/A',
			product: {
				uuid: '8ed3cfcf-2cfe-4fa6-973f-107d6b6906ca',
				title: 'Cryogenic Subscription Yearly',
				type: 'cryo',
				subtitle: 'Qui error ut a beatae.',
				slug: 'cryogenic-subscription-yearly',
				description:
					'Earum optio omnis quis sint. Esse vel doloribus est porro. Eligendi rerum dolor illo voluptatem soluta facere quia.',
				additional_info: null,
				card_caption: 'quisquam',
				advantages: null,
				with_subscription: true,
				subscription_repeats_frequency: 12,
				subscription_repeats_frequency_target: 'month',
				subscription_billing_cycles: 1,
				visible_from: '2018-09-17T00:00:00.000000Z',
				is_hidden: false,
				sku: 'in',
				stock: 982,
				unlimited: false,
				is_cryogenic: true,
				is_addon: true,
				is_virtual: true,
				price: 145,
				number_of_kits: 1,
				meta: null,
				onboarding_route: null,
				deleted_at: null,
				subscription_name: 'yearly',
				stripe_price: 'price_1JhbVOJFHhuyWUNBpIduvEMs',
			},
		},
	],
	vials: [
		{
			id: 3000032,
			// @ts-ignore
			status: 'permanent_storage',
			sample_collection_date: null,
			analysis_completed_date: null,
			destruction_date: null,
			permanent_storage_date: null,
			scheduled_destruction_date: null,
			storage_facility: 'AZENTA_INDIANA',
			storage_facility_barcode: null,
			notes: null,
			transfer_tank_id: null,
			transfer_tank_box: null,
			transfer_tank_box_position: null,
			deleted_at: null,
			created_at: '2022-08-22T14:19:56.000000Z',
			updated_at: '2022-09-14T08:42:50.000000Z',
			sti_status: null,
			sti_status_changed_at: null,
			sti_status_changed_by: null,
			sti_result_id: null,
			sti_notes: null,
			sti_positive_result_id: null,
		},
		{
			id: 3000033,
			// @ts-ignore
			status: 'permanent_storage',
			sample_collection_date: null,
			analysis_completed_date: null,
			destruction_date: null,
			permanent_storage_date: null,
			scheduled_destruction_date: null,
			storage_facility: 'AZENTA_INDIANA',
			storage_facility_barcode: null,
			notes: null,
			transfer_tank_id: null,
			transfer_tank_box: null,
			transfer_tank_box_position: null,
			deleted_at: null,
			created_at: '2022-08-22T14:19:56.000000Z',
			updated_at: '2022-09-14T08:42:50.000000Z',
			sti_status: null,
			sti_status_changed_at: null,
			sti_status_changed_by: null,
			sti_result_id: null,
			sti_notes: null,
			sti_positive_result_id: null,
		},
		{
			id: 3000034,
			// @ts-ignore
			status: 'permanent_storage',
			sample_collection_date: null,
			analysis_completed_date: null,
			destruction_date: null,
			permanent_storage_date: null,
			scheduled_destruction_date: null,
			storage_facility: 'AZENTA_FRESNO',
			storage_facility_barcode: null,
			notes: null,
			transfer_tank_id: null,
			transfer_tank_box: null,
			transfer_tank_box_position: null,
			deleted_at: null,
			created_at: '2022-08-22T14:19:56.000000Z',
			updated_at: '2022-09-14T08:42:50.000000Z',
			sti_status: null,
			sti_status_changed_at: null,
			sti_status_changed_by: null,
			sti_result_id: null,
			sti_notes: null,
			sti_positive_result_id: null,
		},
		{
			id: 3000035,
			// @ts-ignore
			status: 'permanent_storage',
			sample_collection_date: null,
			analysis_completed_date: null,
			destruction_date: null,
			permanent_storage_date: null,
			scheduled_destruction_date: null,
			storage_facility: 'AZENTA_FRESNO',
			storage_facility_barcode: null,
			notes: null,
			transfer_tank_id: null,
			transfer_tank_box: null,
			transfer_tank_box_position: null,
			deleted_at: null,
			created_at: '2022-08-22T14:19:56.000000Z',
			updated_at: '2022-09-14T08:42:50.000000Z',
			sti_status: null,
			sti_status_changed_at: null,
			sti_status_changed_by: null,
			sti_result_id: null,
			sti_notes: null,
			sti_positive_result_id: null,
		},
	],
	product: {
		uuid: '0c57347c-c52d-45d3-a7ee-bf2300c0b9cd',
		title: 'Semen Analysis',
		type: 'analyses',
		subtitle: 'Ut aliquid corporis voluptate assumenda quis quas sint et.',
		slug: 'semen-analysis',
		description:
			'Id aliquid hic necessitatibus et eos. Ipsa voluptas est dolor tenetur fugit doloremque omnis. Velit qui est eos recusandae voluptates. Sit sint quo reiciendis sint labore laboriosam eius.',
		additional_info: null,
		card_caption: 'facere',
		advantages: null,
		with_subscription: false,
		subscription_repeats_frequency: null,
		// @ts-ignore
		subscription_repeats_frequency_target: null,
		subscription_billing_cycles: null,
		visible_from: '2018-09-17T00:00:00.000000Z',
		is_hidden: false,
		sku: 'LMP-MSTR',
		stock: 982,
		unlimited: false,
		is_cryogenic: false,
		is_addon: false,
		is_virtual: false,
		price: 195,
		number_of_kits: 1,
		meta: null,
		onboarding_route: null,
		deleted_at: null,
		subscription_name: null,
		stripe_price: null,
	},
}
