<template>
	<Icon
		name="spinner"
		:class="{
			'icon-sm': size === 'sm',
			'icon-md': size === 'md',
			'icon-lg': size === 'lg',
			'icon-xl': size === 'xl',
		}"
		class="spin-animation"
	/>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	props: {
		size: {
			type: String,
			default: 'md',
		},
	},
})
</script>
<style></style>
