import { ProductsUuid } from '@/modules/checkout/enum/productsUuid'
import store, { RootState } from '@/store'
import { RouteLocationNormalizedLoaded } from 'vue-router'
import axios from 'axios'
import { PackagesUuid } from '@/modules/checkout/enum/packagesUuid'
import { trackSegment } from '@/modules/analytics/track'
import { ProductEventType, TrackingEvents } from '@/modules/common/utils/trackingEvents'
import { ItemAttributes } from '@/modules/items/types/itemTypes'
import { error } from '@/components/common/NotificationPlugin'
import { OrderAttributes } from '@/modules/orders/types/orderTypes'
import {
	Product,
	PromoCodeData,
	PromoCodeItem,
	SessionProduct,
	UrlProduct,
} from '@/modules/checkout/types/checkoutTypes'
import { debounce } from 'lodash-es'
import { Store } from 'vuex'

export const getCheckoutUrl = (
	cart: SessionProduct[],
	lastAppliedPromoCode: PromoCodeData | null,
): string => {
	const products = getProductsForSegment(cart)
	const totals = store.getters['checkout/totals']
	trackSegment({
		name: TrackingEvents.CHECKOUT_STARTED.name,
		options: {
			// TODO: order_id: ???,
			value: totals?.total,
			discount: totals?.discounts,
			currency: 'USD',
			products,
			affiliation: localStorage.getItem('affiliate'),
		},
	})

	let arr = []

	const checkIdT = (obj: SessionProduct): boolean => obj.id === ProductsUuid.TOMORROW_UUID
	const checkIdF = (obj: SessionProduct): boolean => obj.id === ProductsUuid.PLATINUM_UUID
	const checkIdAddon = (obj: SessionProduct): boolean => obj.id === ProductsUuid.DNA_UUID
	const checkIdInstallments3 = (obj: SessionProduct): boolean => obj.extradata === 'in_3_payments'
	const cartOnlyHasInstallments3 = cart.every(checkIdInstallments3)

	for (let i in cart) {
		const { id, count, extradata } = cart[i]
		arr.push({
			product_id: id,
			qty: count,
			extradata,
		})
	}

	if ((cart.some(checkIdT) || cart.some(checkIdF)) && !cart.some(checkIdAddon)) {
		arr.push({ product_id: ProductsUuid.DNA_UUID, qty: '1' })
	}

	const baseURL = 'https://client.givelegacy.com/checkouts/?items=' + JSON.stringify(arr)
	let finalURL = baseURL + ''

	if (localStorage.getItem('affiliate') != null) {
		finalURL = baseURL + '&affiliate=' + localStorage.getItem('affiliate') + ''
	} else if (cartOnlyHasInstallments3) {
		finalURL = baseURL + '&splitIt=true&number_of_installments=3'
	}

	if (lastAppliedPromoCode?.code) {
		finalURL += '&discount=' + lastAppliedPromoCode?.code
	}

	return finalURL
}

export async function resolveUrl(route: RouteLocationNormalizedLoaded) {
	const {
		insurance = null,
		discount = null,
		email = null,
		items = null,
		abandon = null,
		withdrawalItemUuid = null,
		gift = null,
	} = route.query

	const products = store.state.checkout?.products
	if (products?.length === 0) {
		await store.dispatch('checkout/getAllProducts')
	}

	await resolveUrlProducts(items as string, store.state.checkout?.products)
	await resolveEmail(email as string)
	await resolveDiscount(discount as string)
	await resolveInsurance(insurance as string)
	await resolveAbandon(abandon as string)
	await resolveWithdrawal(withdrawalItemUuid as string)
	await resolveGift(gift as string)
}

export async function resolveWithdrawal(withdrawalUuid: string | null) {
	if (!withdrawalUuid) {
		return
	}

	await store.dispatch('checkout/addWithdrawal', withdrawalUuid)
}

export async function resolveGift(gift: string | null) {
	await store.dispatch('checkout/setIsGift', gift === 'true')
}

export async function resolveAbandon(email: string) {
	if (!email) {
		return
	}
	const response = await axios.get(`/checkout-intent/${email}`)
	const { info: abandon } = response

	if (!abandon) {
		return
	}

	let { items, ...rest } = abandon
	items = items.map((item: Record<string, SessionProduct>) => ({
		product_id: item.id,
		qty: item.count,
	}))

	await resolveUrlProducts(items, store.state.checkout.products)
	await store.commit('checkout/setCheckoutModel', rest)
}

export async function resolveInsurance(urlInsuranceUuid: string) {
	try {
		if (!urlInsuranceUuid) {
			return
		}

		await store.commit('checkout/updateCheckoutModel', {
			key: 'insurance.application_uuid',
			value: urlInsuranceUuid,
		})
		resolveUrlProducts(
			[{ product_id: PackagesUuid.INSURANCE_UUID, qty: 1 }],
			store.state.checkout.products,
		)
	} catch (e) {
		console.log('e', e)
		return null
	}
}

export async function resolveDiscount(discount: string) {
	const email = store.state.checkout.checkoutModel?.account_details?.email

	if (!discount) {
		return
	}

	const promoCodeInfo = {
		account_details: {
			email,
		},
		code: discount,
		items: store.state.checkout.cart.map((item: SessionProduct) => ({
			product_uuid: item.id,
			quantity: item.count,
		})),
	}

	const data = await store.dispatch('checkout/validatePromoCode', promoCodeInfo)

	if (!data.success) {
		error(data.hint || 'Invalid promo code')
	}

	return promoCodeInfo
}

export async function resolveEmail(email: string | null) {
	if (email) {
		await store.commit('checkout/updateCheckoutModel', {
			key: 'account_details.email',
			value: email,
		})
	}
}

export async function resolveUrlProducts(
	urlItems: string | Array<UrlProduct>,
	products: Product[],
) {
	try {
		const items: UrlProduct[] | undefined = Array.isArray(urlItems)
			? urlItems
			: JSON.parse(urlItems)

		if (!items) {
			return
		}

		await store.dispatch('checkout/setCart', [])

		items.map(async (item: UrlProduct) => {
			const product: Product | undefined = products.find(p => p?.uuid === item.product_id)

			if (!product) {
				return
			}

			await store.dispatch(
				'checkout/addOrUpdateCartItem',
				checkoutProductToSessionProduct(product, item),
			)
		})
	} catch (e) {
		console.log('Error resolving url products', urlItems)
		return
	}
}

export function checkoutProductToSessionProduct(
	product: Product,
	item: UrlProduct,
): SessionProduct {
	return {
		id: product.uuid,
		count: item.qty ?? 1,
		name: product.title ? product.title : product.name ? product.name : '',
		...(item.extradata && { extradata: item.extradata }),
	}
}

export function isCryogenicProductOrVirtual(product: Product): boolean {
	if (product.is_cryogenic || product.is_virtual) {
		return true
	}

	if (product.products) {
		return product.products.some((product: Product) => product.is_cryogenic || product.is_virtual)
	}

	return false
}

export function cartToCheckoutProduct(): PromoCodeItem[] {
	return store.state.checkout.cart.map((item: SessionProduct) => {
		return {
			product_uuid: item.id,
			quantity: item.count,
		}
	})
}

export function getPurchasedProducts(order: OrderAttributes): ProductEventType[] | undefined {
	const lastAppliedPromoCode = store.getters[
		'checkout/lastAppliedPromoCode'
	] as PromoCodeData | null

	return order.items?.map((item: ItemAttributes) => ({
		name: item.title,
		id: item?.product?.sku,
		price: item?.product?.price?.toString(),
		category: item.product_type,
		quantity: item.quantity,
		coupon: lastAppliedPromoCode?.targets?.includes(item?.product?.uuid ?? '')
			? lastAppliedPromoCode.code
			: '',
	}))
}

// Formats an array of Products for Segment consumption, followingf their api
// https://segment.com/docs/connections/spec/ecommerce/v2/
export function getProductsForSegment(
	cart: SessionProduct[],
	products: Product[] = store.state.checkout.products,
): Product[] | Array<any> {
	return cart
		.map((item: SessionProduct) => {
			const product = products.find((p: Product) => p.uuid === item.id)
			const lastAppliedPromoCode = store.getters[
				'checkout/lastAppliedPromoCode'
			] as PromoCodeData | null

			if (!product) {
				return null
			}

			return {
				uuid: product.uuid,
				product_id: product.uuid,
				name: product.name || product.title,
				price: product.price,
				quantity: item.count,
				currency: 'USD',
				coupon: lastAppliedPromoCode?.targets?.includes(product.uuid)
					? lastAppliedPromoCode.code
					: '',
			}
		})
		.filter(Boolean)
}

export function trackProductAddedToCart(
	productUuid: string,
	location: string,
	quantity: number = 1,
) {
	const product = store.getters['checkout/productByUuid'](productUuid)

	trackSegment({
		name: TrackingEvents.PRODUCT_ADDED.name,
		options: {
			name: product.name || product.title,
			price: product.price,
			quantity: quantity,
			product_id: product.uuid,
			uuid: product.uuid,
			location: location,
			currency: 'USD',
			url: window.location.href,
		},
	})
}

export function trackProductRemovedFromCart(productUuid: string, quantity: number = 1) {
	const product = store.getters['checkout/productByUuid'](productUuid)

	trackSegment({
		name: TrackingEvents.PRODUCT_REMOVED.name,
		options: {
			name: product.name || product.title,
			price: product.price,
			quantity: quantity,
			product_id: product.uuid,
			uuid: product.uuid,
			currency: 'USD',
			url: window.location.href,
		},
	})
}

export const debouncedEmailValidate = debounce((value: string) => {
	return axios.post('/validate-email', {
		email: value,
	})
}, 500)

export const getCheckoutSegmentPayload = (store: Store<RootState>, restData: any) => {
	const products = getProductsForSegment(store.state.checkout.cart)
	const totals = store.getters['checkout/totals']
	const lastAppliedPromoCode = store.getters['checkout/lastAppliedPromoCode']

	return {
		value: totals.total,
		discount: Math.abs(lastAppliedPromoCode?.discounts || 0),
		currency: 'USD',
		coupon: lastAppliedPromoCode?.code || '',
		shipping_method: undefined,
		payment_method: store.state.checkout.checkoutModel?.payment?.payment_method,
		products: products,
		...restData,
	}
}

export const segmentConversion = (
	products: Product[],
	allProducts: Product[],
	order_id: string,
) => {
	const foreverPackage = products.find(
		(product: Product) => product.uuid === PackagesUuid.FOREVER_UUID,
	)
	let foreverTotal = 0
	const tomorrow = products.find((product: Product) => product.uuid === PackagesUuid.TOMORROW_UUID)
	let tomorrowTotal = 0
	let cryogenicTotal = 0

	if (foreverPackage) {
		foreverTotal = foreverPackage.quantity * foreverPackage.price

		trackSegment({
			name: TrackingEvents.FOREVER_CONVERSION.name,
			options: {
				value: foreverTotal,
				order_id: order_id,
				currency: 'USD',
			},
		})
	}

	if (tomorrow) {
		tomorrowTotal = tomorrow.quantity * tomorrow.price

		trackSegment({
			name: TrackingEvents.FOR_TOMORROW_CONVERSION.name,
			options: {
				value: tomorrowTotal,
				revenue: tomorrowTotal,
				order_id: order_id,
				currency: 'USD',
			},
		})
	}

	const cryogenics = products
		.filter(function (product: Product) {
			return !!allProducts.find(p => p.type === 'cryo' && p.uuid === product.uuid)
		})
		.forEach(function (product) {
			cryogenicTotal += product.price * product.quantity
		})

	if (cryogenicTotal > 0) {
		trackSegment({
			name: TrackingEvents.CRYO_CONVERSION.name,
			options: {
				value: cryogenicTotal,
				revenue: cryogenicTotal,
				order_id: order_id,
				currency: 'USD',
			},
		})
	}

	if (tomorrow || foreverPackage || cryogenics) {
		const totalValue = cryogenicTotal + foreverTotal + tomorrowTotal
		trackSegment({
			name: TrackingEvents.OVERALL_CONVERSION.name,
			options: {
				value: totalValue,
				revenue: totalValue,
				order_id: order_id,
				currency: 'USD',
			},
		})
	}
}
