import {
	EcommerceEventType,
	EventRecord,
	GtmEventType,
	ProductEventType,
} from '@/modules/common/utils/trackingEvents'
import store from '@/store'
import { isProduction } from '@/modules/common/utils/envUtils'
import { ClientType } from '@/modules/auth/types/authTypes'
import { OrderAttributes } from '@/modules/orders/types/orderTypes'
import { debounce } from 'lodash-es'

export async function trackSegment(event: EventRecord) {
	const { disableTrackEvents } = store.state.auth
	console.log('segment tracking disabled: ', disableTrackEvents, isProduction(), event.name)

	if (disableTrackEvents) {
		return
	}

	if (!isProduction()) {
		return
	}

	let eventName = event.name

	// TODO: Uncomment when testing
	// eventName = `TEST_${eventName}`;

	if (window.analytics) {
		window.analytics.track(
			eventName,
			{
				...event.options,
				...{
					GAclientId: window.GAclientId,
					GAsessionId: window.GAsessionId,
					GAsessionNum: window.GAsessionNum,
				},
			},
			{
				integrations: {
					'Google Universal Analytics': {
						clientId: window.GAclientId,
					},
				},
			},
		)
	}
}

function trackGtmEvent(client: ClientType | undefined, ecommerce: EcommerceEventType) {
	if (!window.dataLayer) {
		return
	}

	window.dataLayer = window.dataLayer || []

	const event: GtmEventType = {
		event: 'GTMecommerce',
		eventCategory: 'enhanced-ecommerce',
		eventAction: 'purchase',
		eventLabel: '',
		userId: client?.number,
		email: client?.email,
		ecommerce: {},
	}

	if (ecommerce) {
		event.ecommerce = ecommerce
	}

	window.dataLayer.push(event)
}

export function trackCheckoutGTM(
	order: OrderAttributes,
	products: ProductEventType[] | undefined,
): void {
	const ecommerceObject: EcommerceEventType = {
		currencyCode: 'USD',
		purchase: {
			actionField: {
				id: order.po_number.toString(),
				revenue: order.revenue?.toFixed(2)?.toString(),
				tax: '',
				shipping: '',
				coupon: store.getters['checkout/lastAppliedPromoCode']?.code || '',
			},
			products: products,
		},
	}

	trackGtmEvent(order?.client, ecommerceObject)
}

export const debouncedTrackEvent = debounce(async event => {
	await trackSegment(event)
}, 500)
