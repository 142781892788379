import { ActionTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store'
import axios from 'axios'
import {
	BenefitsProvider,
	WinFertilityEligibilityModel,
} from '@/modules/benefitsProviders/types/benefitsProvidersTypes'

export type State = {
	benefitsProvider: BenefitsProvider | null
}

export const state = (): State => ({
	benefitsProvider: null,
})

const mutations: MutationTree<State> = {
	setBenefitsProvider(state: State, data: BenefitsProvider) {
		state.benefitsProvider = data
	},
}

const actions: ActionTree<State, RootState> = {
	async checkFertilityBenefitsEligibility({ commit }, model: WinFertilityEligibilityModel) {
		const benefitsProviderResponse = await axios.post(
			'/benefits-providers/win-fertility/check/',
			model,
		)

		commit(
			'setBenefitsProvider',
			benefitsProviderResponse.benefits_provider_member as BenefitsProvider,
		)

		return benefitsProviderResponse
	},
}

const module: Module<State, RootState> = {
	namespaced: true,
	state,
	mutations,
	actions,
}

export default module
