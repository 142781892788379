<template>
	<component
		v-bind="$attrs"
		ref="button"
		:is="tag"
		:type="buttonType"
		:disabled="disabled || loading"
		:class="{
			'gl-btn-primary': variant === 'primary',
			'gl-btn-secondary': variant === 'secondary',
			'gl-btn-highlight': variant === 'highlight',
			'gl-btn-tertiary': variant === 'tertiary',
			'gl-btn-link': variant === 'link',
			'gl-btn-success': variant === 'success',
			'gl-btn-error': variant === 'error',
			'gl-btn-sm': size === 'sm',
			'gl-btn-md': size === 'md',
			'gl-btn-lg': size === 'lg',
			'gl-btn-icon': iconOnly,
			'gl-btn-round': round,
			'gl-btn-disabled': disabled,
			'gl-btn-loading': loading,
			'inline-flex': !block,
			'w-full flex justify-center': block,
		}"
		class="gl-btn"
	>
		<span
			v-if="loading"
			class="absolute flex w-full items-center justify-center"
		>
			<LoadingIcon :size="size" />
		</span>

		<Icon
			v-if="icon && iconPosition === IconPositions.Left"
			:name="icon"
			:class="{
				'icon-sm': size === 'sm',
				'icon-md': size === 'md',
				'icon-lg': size === 'lg',
				'opacity-0': loading,
				'mr-2': !iconOnly,
				[`${iconClass}`]: iconClass,
			}"
		/>

		<span :class="{ 'opacity-0': loading }">
			<slot></slot>
		</span>
		<Icon
			v-if="icon && iconPosition === IconPositions.Right"
			:name="icon"
			:class="{
				'icon-sm': size === 'sm',
				'icon-md': size === 'md',
				'icon-lg': size === 'lg',
				'opacity-0': loading,
				'ml-2': !iconOnly,
				[`${iconClass}`]: iconClass,
			}"
		/>
	</component>
</template>
<script lang="ts">
import LoadingIcon from '@/components/common/buttons/LoadingIcon.vue'
import { IconsPropType } from '@/modules/common/icons'
import { defineComponent, PropType } from 'vue'

export enum ButtonTypes {
	Button = 'button',
	Submit = 'submit',
	Reset = 'reset',
}

export enum ButtonSizes {
	Sm = 'sm',
	Md = 'md',
	Lg = 'lg',
}

export enum ButtonVariants {
	Primary = 'primary',
	Secondary = 'secondary',
	Highlight = 'highlight',
	Tertiary = 'tertiary',
	Success = 'success',
	Error = 'error',
	Link = 'link',
}

export enum IconPositions {
	Left = 'left',
	Right = 'right',
}

export default defineComponent({
	inheritAttrs: false,
	components: {
		LoadingIcon,
	},
	props: {
		/**
		 * Can be used to render something else than a button. tag="a" for example will render an anchor tag.
		 */
		tag: {
			type: String,
			default: 'button',
		},
		block: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		/**
		 * Button visual variant. This will be displayed in storybook
		 */
		variant: {
			type: String as PropType<`${ButtonVariants}`>,
			default: ButtonVariants.Primary,
		},
		size: {
			type: String as PropType<`${ButtonSizes}`>,
			default: ButtonSizes.Md,
		},
		type: {
			type: String as PropType<`${ButtonTypes}`>,
			default: ButtonTypes.Button,
		},
		icon: {
			type: String as PropType<IconsPropType>,
			default: '',
		},
		/**
		 * Icon position left|right after the text
		 */
		iconPosition: {
			type: String as PropType<`${IconPositions}`>,
			default: IconPositions.Right,
		},
		iconOnly: {
			type: Boolean,
			default: false,
		},
		round: {
			type: Boolean,
			default: false,
		},
		iconClass: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			IconPositions,
		}
	},
	computed: {
		buttonType(): string | undefined {
			if (['a', 'router-link'].includes(this.tag)) {
				return undefined
			}
			return this.type
		},
	},
	methods: {
		focus() {
			this.$refs.button?.focus()
		},
	},
})
</script>
<style lang="scss" scoped>
.base-button {
	&:focus {
		@apply ring-1 ring-evergreen-600;
	}
}
</style>
