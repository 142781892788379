import i18n from '@/i18n'
import { Data } from '@/modules/common/apiConfig'
import { ScrollableSection } from '@/modules/common/types/common'
import { reactive } from 'vue'

export enum SectionStates {
	Azoospermic = 'Azoospermic',
	Normal = 'Normal',
	Low = 'Low',
	High = 'High',
	Withdrawn = 'Withdrawn',
	LowPostThawMotility = 'Low_Post_Thaw_Motility',
	LowConcentration = 'Low_Concentration',
}

export type SectionDetails = {
	value: number | null
	state: SectionStates
	topPercentValue?: number
	wasAnalysed: boolean
}

export type SectionRangePercentiles = {
	min: number
	topPercent: number
}

export type LabNote = {
	id: number
	header: string
	body: string
	key: string
}

export type SectionWhoRange = {
	min: number
	max: number
	normal: number
	maxSlider: number
	percentiles?: SectionRangePercentiles[]
}

export type SectionWhoRangesRecord = Record<string, SectionWhoRange>

export enum ConsideringFreezingOptions {
	Yes = 'yes',
	No = 'no',
	NotSure = 'not_sure',
}

export enum ResultsSections {
	UnderstandingYourResults = 'understanding-your-results',
	FutureHealthInsights = 'future-health-insights',
	TotalCount = 'total-count',
	TotalMotileCount = 'total-motile-count',
	Concentration = 'concentration',
	Motility = 'motility',
	Volume = 'volume',
	Morphology = 'morphology',
	DNAFragmentation = 'dna-fragmentation',
	CryogenicStorage = 'cryogenic-storage',
	LabRecommendations = 'lab-recommendations',
	PersonalizedRecommendations = 'personalized-recommendations',
}

export enum StorageStates {
	// Currently BoughtStorage & AnalysisOnly are treated the same so they are combined to BoughtStorageOrAnalysisOnly
	BoughtStorage = 'Bought_Storage',
	BoughtStorageAndCryoNotMatched = 'Bought_Storage_And_Cryo_Not_Matched',
	BoughtStorageOrAnalysisOnly = 'Bought_Storage_Or_Analysis_Only',
	ConsideringStorage = 'Considering_Storage',
	NotConsideringStorage = 'Not_Considering_Storage',
}

export enum FutureHealthInsightsSections {
	TesticularCancer = 'Testicular_cancer',
	Diabetes = 'Diabetes',
	CardiovascularDisease = 'Cardiovascular_disease',
}

export type ResultsSectionStateMessages = Partial<
	Record<SectionStates, { header: string; subheader: string }>
>

export type MessageSection = Partial<Record<StorageStates, string>>

export type ResultsMessageSection = {
	title: string
	stateMessages: Partial<Record<SectionStates, MessageSection>>
	showIf?: (sectionState: SectionStates, storageState: StorageStates) => boolean
}

export type ResultsSliderOptions = {
	sliderRanges: SectionWhoRange
	customNormalRangePercent?: number
	valueFormatter: (value: number) => string
	reverseGradient?: boolean
}

export type ResultDocumentData = Data<ResultDocument>

export type ResultDocument = {
	id: number
	is_azoospermic: boolean
	is_post_vasectomy: boolean
	is_ready: boolean
	is_dna_fragmentation: boolean
	is_analysis_only: boolean
	step: number
	clinic: string
	lab_address: string
	lab_director: string
	clia_number: string
	technologist: string
	observation: string
	dna_fragmentation: number
	collected_at: string
	analysis_time: string
	analyzed_at: string
	reported_at: string
	abstinence_days: number
	entire_specimen_collected: string | null
	specimen_portion_missing: string | null
	specimen_percentage_lost: number | null
	fraction_lost: boolean
	color: string
	concentration_below_measurable: boolean
	concentration: number
	actual_ejaculate_concentration: number | null
	pre_wash_concentration: number
	volume: number
	volume_source: string
	pre_wash_volume: number
	total_count: number
	total_motile_sperm: number
	total_motility: number
	progressive_motility: number
	velocity_average: number
	alh_mean: number
	linearity: number
	beat_frequency: number
	who_edition: boolean
	normal: number
	abnormal: number
	wbc: string
	amorphous_read: number
	acrosome_deficient: number
	double_head: number
	large_head: number
	amorphous_large_head: number
	tapering_head: number
	pyriform_head: number
	vacuolated_head: number
	neck_midpiece_defect: number
	cytoplasmic_droplet: number
	coiled_tail: number
	multiple_tail: number
	vials_cryopreserved: number
	post_thaw_concentration: number
	post_thaw_motility: number | null
	overall_assessment: string
	drink_recommendations: string
	sleep_recommendations: string
	clothes_recommendations: string
	exercise_recommendations: string
	ready_at: string
	clinician_id: number
	data: []
	downloadableLink: string
	created_at: string
	updated_at: string
	recommendations: []
	activeLabNotes?: Array<Data<LabNote>> | Array<LabNote>
}

export const semenAnalysisScrollableSections: Array<ScrollableSection> = reactive([
	{
		label: i18n.t('Understanding your results'),
		href: ResultsSections.UnderstandingYourResults,
		observer: {
			isIntersecting: false,
			intersectionRatio: 0,
		},
	},
	{
		label: i18n.t('Future health insights'),
		href: ResultsSections.FutureHealthInsights,
		observer: {
			isIntersecting: false,
			intersectionRatio: 0,
		},
	},
	{
		label: i18n.t('Total count'),
		href: ResultsSections.TotalCount,
		observer: {
			isIntersecting: false,
			intersectionRatio: 0,
		},
	},
	{
		label: i18n.t('Total motile count'),
		href: ResultsSections.TotalMotileCount,
		observer: {
			isIntersecting: false,
			intersectionRatio: 0,
		},
	},
	{
		label: i18n.t('Volume'),
		href: ResultsSections.Volume,
		observer: {
			isIntersecting: false,
			intersectionRatio: 0,
		},
	},
	{
		label: i18n.t('Concentration'),
		href: ResultsSections.Concentration,
		observer: {
			isIntersecting: false,
			intersectionRatio: 0,
		},
	},
	{
		label: i18n.t('Motility'),
		href: ResultsSections.Motility,
		observer: {
			isIntersecting: false,
			intersectionRatio: 0,
		},
	},
	{
		label: i18n.t('Morphology'),
		href: ResultsSections.Morphology,
		observer: {
			isIntersecting: false,
			intersectionRatio: 0,
		},
	},
	{
		label: i18n.t('DNA fragmentation'),
		href: ResultsSections.DNAFragmentation,
		observer: {
			isIntersecting: false,
			intersectionRatio: 0,
		},
	},
	{
		label: i18n.t('Cryogenic storage'),
		href: ResultsSections.CryogenicStorage,
		observer: {
			isIntersecting: false,
			intersectionRatio: 0,
		},
	},
	{
		label: i18n.t('Lab observations'),
		href: ResultsSections.LabRecommendations,
		observer: {
			isIntersecting: false,
			intersectionRatio: 0,
		},
	},
	{
		label: i18n.t('Personalized recommendations'),
		href: ResultsSections.PersonalizedRecommendations,
		observer: {
			isIntersecting: false,
			intersectionRatio: 0,
		},
	},
])

export enum ResultsVolumeSources {
  Calculated = 'calculated',
  Standardized = 'standardized',
  ClientReported = 'client-reported',
}
