import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store'
import axios from 'axios'
import { get } from 'lodash-es'
import { DataList } from '@/modules/common/apiConfig'
import { AuthorizedUser } from '@/modules/authorizedUsers/types/authorizedUserTypes'

export type State = {
	authorizedUser: AuthorizedUser | null
}

export const state = (): State => ({
	authorizedUser: null,
})

const mutations: MutationTree<State> = {
	setAuthorizedUser(state: State, authorizedUser: AuthorizedUser) {
		state.authorizedUser = authorizedUser
	},
}

const actions: ActionTree<State, RootState> = {
	async getAuthorizedUser({ commit }): Promise<AuthorizedUser | void> {
		const { data }: DataList<AuthorizedUser> = await axios.get('/restify/authorized-users')

		const authorizedUser: AuthorizedUser = get(data, '0.attributes')

		if (!authorizedUser) {
			return
		}

		commit('setAuthorizedUser', authorizedUser)

		return authorizedUser
	},
	async updateAuthorizedUser({ commit, dispatch }, requestData): Promise<any | void> {
		await axios.post('/restify/authorized-users', requestData)
		dispatch('getAuthorizedUser')
	},
	async deleteAuthorizedUser({ commit }): Promise<any | void> {
		await axios.post('/restify/authorized-users/actions?action=delete-authorized-user')
		commit('setAuthorizedUser', null)
	},
}

const getters: GetterTree<State, RootState> = {}

const module: Module<State, RootState> = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

export default module
