<template>
	<BaseButton
		:icon="Icons.X"
		icon-only
		round
		size="sm"
		variant="highlight"
	>
	</BaseButton>
</template>
<script lang="ts">
import { PropType } from 'vue'
import LoadingIcon from '@/components/common/buttons/LoadingIcon.vue'
import { ButtonSizes } from './BaseButton.vue'

export default {
	components: {
		LoadingIcon,
	},
	props: {
		loading: Boolean,
		size: {
			type: String as PropType<`${ButtonSizes}`>,
			default: ButtonSizes.Md,
		},
	},
}
</script>
<style></style>
