import { computed } from 'vue'
import { Item, ItemAttributes } from '@/modules/items/types/itemTypes'
import store from '@/store'
import { SelectOption } from '@/components/common/form/BaseSelect.vue'
import { ConsideringFreezingOptions } from '@/modules/items/types/resultsTypes'

export default function useTesting(item: Item | null = null) {
	const boughtStorageVal = computed({
		get() {
			return (item?.relationships?.cryogenicAddons?.length || 0) > 0
		},
		set(value: boolean) {
			store.commit('items/setBoughtStorage', {
				item: item,
				value,
			})
		},
	})

	const considerFreezingOptions = computed<SelectOption[]>(() => {
		const options: SelectOption[] = [
			{
				label: 'Yes',
				value: ConsideringFreezingOptions.Yes,
			},
			{
				label: 'Not Sure',
				value: ConsideringFreezingOptions.NotSure,
			},
			{
				label: 'No',
				value: ConsideringFreezingOptions.No,
			},
		]

		return options
	})

	const considerFreezingVal = computed({
		get() {
			return item?.attributes.considering_freezing as string
		},
		set(value: string) {
			store.commit('items/setConsideringFreezing', {
				item: item,
				value,
			})
		},
	})

	function setSubscriptionsExist(value: boolean) {
		store.commit('cryogenics/setHasCryogenic', value)
		store.commit('auth/updateClientValue', {
			key: 'has_active_subscriptions',
			value: value,
		})
	}

	function setStoredVials(items: ItemAttributes[]) {
		store.commit('cryogenics/setItems', items)

		if (items.length) {
			setSubscriptionsExist(true)
		}
	}

	return {
		boughtStorageVal,
		considerFreezingOptions,
		considerFreezingVal,
		setSubscriptionsExist,
		setStoredVials,
	}
}
