<template>
	<nav
		:class="{ 'fixed top-0 left-0 w-full': mobileMenu }"
		class="bg-evergreen-800 p-8 dark z-20"
	>
		<div class="mx-auto">
			<div class="relative flex items-center justify-between">
				<div class="flex items-center lg:hidden">
					<!-- Mobile menu button-->
					<BaseButton
						:aria-expanded="mobileMenu"
						icon-only
						variant="tertiary"
						size="sm"
						aria-controls="mobile-menu"
					>
						<Icon
							v-show="!mobileMenu"
							name="menu"
							class="w-8 text-white"
							@click="toggleMobileMenu"
						/>
						<Icon
							v-show="mobileMenu"
							name="x"
							class="w-8 text-white"
							@click="toggleMobileMenu"
						/>
					</BaseButton>
				</div>
				<div class="flex-1 flex items-center justify-center lg:mainLinks-stretch lg:justify-start">
					<router-link
						class="flex-shrink-0 flex items-center cursor-pointer"
						to="/home"
					>
						<img
							src="/img/logo.png"
							alt="legacy-logo"
							class="block h-8 w-auto"
							width="32px"
							height="32px"
						/>
					</router-link>
					<div class="hidden lg:flex h-full min-h-full md:ml-8 xl:ml-16 mr-16 whitespace-nowrap">
						<div
							id="menu"
							class="flex space-x-8 h-full"
						>
							<NavLink
								v-for="item in navBarLinks"
								:key="item.name"
								:item="item"
								class="text-white text-md hover:bg-gray-700 hover:text-white text-md hover:no-underline focus:no-underline relative"
							>
								<p class="relative">
									{{ item.name }}
									<template v-if="item.badge && item.badgeVisibility">
										<span class="text-error-300 absolute top-0 text-xs ml-2">{{ item.badge }}</span>
									</template>
								</p>
							</NavLink>
						</div>
					</div>
				</div>
				<div class="flex items-center">
					<div
						class="relative"
						v-click-outside="closeProfileMenu"
					>
						<div class="flex flex-row items-center hidden lg:flex">
							<HelpCenterButton class="mr-4" />
							<ContactButton />
							<div class="w-px h-6 bg-evergreen-600 lg:mx-4 xl:mx-8"></div>
							<BaseButton
								:icon="showProfile ? 'chevron-up' : 'chevron-down'"
								variant="tertiary"
								size="sm"
								class="-ml-3 profile-button"
								@click="toggleProfileMenu"
							>
								<span class="text-md">
									{{ userPreferredFullName }}
								</span>
							</BaseButton>
						</div>
						<div class="lg:hidden">
							<BaseButton
								id="user-menu-button"
								:aria-expanded="showProfile"
								variant="tertiary"
								icon-only
								size="sm"
								aria-haspopup="true"
								@click="toggleProfileMenu"
							>
								<Icon
									name="user-circle"
									class="w-8 text-evergreen-400"
								/>
							</BaseButton>
						</div>
						<transition
							enter-active-class="transition ease-out duration-100"
							enter-class="transform opacity-0 scale-95"
							enter-to-class="transform opacity-100 scale-100"
							leave-active-class="transition ease-in duration-75"
							leave-class="transform opacity-100 scale-100"
							leave-to-class="transform opacity-0 scale-95"
							appear
						>
							<div
								id="profile-menu"
								v-show="showProfile"
								role="menu"
								aria-orientation="vertical"
								aria-labelledby="user-menu-button"
								tabindex="-1"
								class="origin-top-right fixed z-10 py-2 pb-4 right-0 mt-4 mr-4 space-y-3 w-60 rounded-md shadow-lg bg-evergreen-700"
							>
								<NavLink
									v-for="item in userLinks"
									:key="item.name"
									:item="item"
									:class="item.class"
									class="px-4 py-1 text-sm text-white flex items-center hover:no-underline hover:bg-evergreen-800"
									@click="onMenuClick(item)"
								>
									<Icon
										:name="item.icon"
										class="w-6 mr-2 text-evergreen-400"
									/>
									{{ item.name }}
								</NavLink>
							</div>
						</transition>
					</div>
				</div>
			</div>
		</div>

		<transition
			name="fade"
			mode="out-in"
		>
			<div
				id="mobile-menu"
				v-show="mobileMenu"
				class="w-full flex flex-col overflow-y-auto justify-between px-16 py-9 fixed left-0 bg-evergreen-800"
			>
				<div class="space-y-9">
					<div
						v-for="item in mainLinks"
						:key="item.name"
						class="flex"
					>
						<NavLink
							:item="item"
							class="text-md text-white hover:no-underline"
							@click="mobileMenu = false"
						>
							<p class="relative">
								{{ item.name }}
								<template v-if="item.badge">
									<span class="text-error-300 absolute top-0 text-xs ml-2 right-[-30px]">
										{{ item.badge }}
									</span>
								</template>
							</p>
						</NavLink>
					</div>
				</div>

				<div class="mt-8">
					<HelpCenterButton />
					<ContactButton />
				</div>
			</div>
		</transition>
	</nav>
	<ConsultationsDialog v-model="showConsultationsDialog" />
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import NavLink, { NavLinkType } from '@/components/common/navbar/NavLink.vue'
import { getWebsiteLink } from '@/modules/common/utils/linkUtils'
import { trackSegment } from '@/modules/analytics/track'
import { TrackingEvents } from '@/modules/common/utils/trackingEvents'
import { userNamesMixin } from '@/modules/common/mixins/userNamesMixin'
import { useAuth0 } from '@auth0/auth0-vue'

export default defineComponent({
	components: { NavLink },
	mixins: [userNamesMixin],
	data() {
		return {
			showProfile: false,
			mobileMenu: false,
			showConsultationsDialog: false,
			mainLinks: [
				{
					name: this.$t('Home'),
					link: '/home',
					enabled: () => {
						return !this.$user.has_authorized_user_role
					},
				},
				{
					name: this.$t('Analysis'),
					link: '/analysis',
					analytics: () => {
						trackSegment(TrackingEvents.ANALYSIS_VIEWED.NAVBAR)
					},
				},
				{
					name: this.$t('Cryogenic storage'),
					link: '/cryogenic',
					analytics: () => {
						trackSegment(TrackingEvents.CRYOGENIC_VIEWED.NAVBAR)
					},
					enabled: () => {
						return !this.$user.has_authorized_user_role
					},
				},
				{
					name: this.$t('Supplements'),
					link: getWebsiteLink('/sperm-improvement/'),
					enabled: () => {
						return !this.$user.has_authorized_user_role && this.marketable
					},
				},
				{
					name: this.$t('Consultations'),
					method: this.toggleConsultationsDialog,
					analytics: () => {
						trackSegment(TrackingEvents.SCHEDULE_RESULTS_CALL.CLIENT_DASH_NAV_BAR)
					},
					enabled: () => {
						return !this.$user.has_authorized_user_role && this.marketable
					},
				},
			] as NavLinkType[],
			links: [
				{
					name: this.$t('Account information'),
					link: '/profile',
					icon: 'user-circle',
				},
				{
					name: this.$t('Contracts'),
					link: '/contracts',
					icon: 'feather',
				},
				{
					name: this.$t('Manage billing'),
					link: '/manage-billing',
					icon: 'user-circle',
					enabled: () => {
						return !this.$user.has_authorized_user_role
					},
				},
				{
					name: this.$t('Add spouse / partner'),
					link: '/partner',
					icon: 'user-circle',
					enabled: () => {
						return !this.$user.has_authorized_user_role && this.marketable
					},
				},
				{
					name: this.$t('Order history'),
					link: '/orders/history',
					icon: 'package',
					enabled: () => {
						return !this.$user.has_authorized_user_role
					},
				},
				{
					name: this.$t('Take the Lifestyle Survey'),
					link: '/lifestyle',
					icon: 'user-circle',
					analytics: () => {
						trackSegment(TrackingEvents.LIFESTYLE_QUIZ.MENU)
					},
					enabled: () => {
						return !this.$user.has_authorized_user_role && this.marketable
					},
				},
				{
					name: this.$t('Find a urologist'),
					link: '/find-urologist',
					icon: 'search',
					enabled: this.canSeeFindUrologistPage,
				},
				{
					name: this.$t('Logout'),
					link: '/',
					icon: 'log-out',
					class: 'logout-link',
				},
			] as NavLinkType[],
		}
	},
	computed: {
		userLinks() {
			const links = this.links as NavLinkType[]

			return links.filter((link: NavLinkType) => {
				if (!link.enabled) {
					return true
				}

				return link.enabled()
			})
		},
		navBarLinks() {
			const links = this.mainLinks as NavLinkType[]

			return links.filter((link: NavLinkType) => {
				if (!link.enabled) {
					return true
				}

				return link.enabled()
			})
		},
		consultationQuery() {
			return this.$route.query.consultationDialog
		},
		marketable() {
			return !this.$store.getters['auth/isUnmarketable']
		},
	},
	methods: {
		canSeeFindUrologistPage() {
			return this.$client?.has_results_ready && !this.$user.has_authorized_user_role
		},
		toggleProfileMenu() {
			this.showProfile = !this.showProfile
		},
		toggleMobileMenu() {
			this.mobileMenu = !this.mobileMenu
		},
		closeProfileMenu() {
			this.showProfile = false
		},
		async onMenuClick(item: any) {
			if (item.name === this.$t('Logout')) {
				await this.$store.dispatch('auth/logout')
			}
			this.showProfile = false
		},
		toggleConsultationsDialog() {
			this.showConsultationsDialog = true
		},
	},
	watch: {
		mobileMenu: {
			immediate: true,
			handler(value) {
				if (value) {
					document.body.classList.add('overflow-hidden')
				} else {
					document.body.classList.remove('overflow-hidden')
				}
			},
		},
		'$store.state.items.items.length': {
			immediate: true,
			handler(value) {
				if (value && this.consultationQuery) {
					this.toggleConsultationsDialog()
					this.$router.replace('/home')
				}
			},
		},
	},
})
</script>
<style scoped lang="scss">
.logout-link {
	@apply border-t border-evergreen-600;
}

#mobile-menu {
	--navbar-height: 95px;
	height: calc(100% - var(--navbar-height));
	top: var(--navbar-height);
	@apply z-10;
	@screen lg {
		@apply hidden;
	}
}

.contact-us-mobile {
	@apply absolute mx-6 bottom-9;
}

nav .profile-button {
	height: 32px;
}
</style>
<style lang="scss">
#menu,
#mobile-menu {
	a {
		@apply relative;
	}

	a:after {
		content: ' ';
		height: 1px;
		bottom: -4px;
		@apply absolute w-full transition duration-100 ease-in-out;
	}

	a:hover,
	a:active,
	.router-link-active {
		&:after {
			@apply bg-gold-700;
		}
	}
}

#profile-menu a.router-link-active {
	@apply bg-evergreen-800 no-underline;
	&:focus {
		@apply no-underline;
	}
}
</style>
