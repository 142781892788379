import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/store'
import { RequestParams } from '@/modules/common/apiConfig'
import axios from 'axios'
import { ItemAttributes } from '@/modules/items/types/itemTypes'
import { BillingsAttributes, StripeCustomerAttributes } from '@/modules/payments/types/paymentTypes'

export type State = {
	items: ItemAttributes[]
	billingsLoading: boolean
}

export const state = (): State => ({
	items: [],
	billingsLoading: false,
})

const mutations: MutationTree<State> = {
	setBillingData(state: State, data: BillingsAttributes) {
		const { items } = data
		state.items = items
	},
	setItemsLoading(state: State, loading: boolean) {
		state.billingsLoading = loading
	},
}

const actions: ActionTree<State, RootState> = {
	async getStripeCustomerPortal(
		{ commit, state },
		requestData,
	): Promise<StripeCustomerAttributes | void> {
		try {
			const stripePortalData: { data: StripeCustomerAttributes } = await axios.post(
				'/billing-portal',
				requestData,
			)

			let { data } = stripePortalData

			return data
		} catch (err: any) {
			if (err.handled) {
				return
			}
		}
	},
	async getBillingData(
		{ commit, state },
		params = {} as RequestParams,
	): Promise<BillingsAttributes | void> {
		try {
			commit('setItemsLoading', true)

			const itemsData: { data: BillingsAttributes } = await axios.get('/billings', {
				params,
			})

			let { data } = itemsData

			commit('setBillingData', data)

			return data
		} catch (err: any) {
			if (err.handled) {
				return
			}
		} finally {
			commit('setItemsLoading', false)
		}
	},
}

const getters: GetterTree<State, RootState> = {}

const module: Module<State, RootState> = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

export default module
