export type EventRecord = {
	name: string
	options?: Record<string, object | string | number | undefined | null>
	id?: object
}

export type ProductEventType = {
	name?: string
	id?: string
	price?: string
	category?: string
	quantity?: number
	coupon?: string
}

export type ActionFieldType = {
	id: string
	revenue?: string
	tax: string
	shipping: string
	coupon: string
}

export type PurchaseType = {
	actionField: ActionFieldType
	products: any
}

export type EcommerceEventType = {
	currencyCode: string
	purchase: PurchaseType
}

export type GtmEventType = {
	event: string
	eventCategory: string
	eventAction: string
	eventLabel: string
	userId?: number
	email?: string
	ecommerce?: EcommerceEventType | {}
}

export const TrackingEvents = {
	ANALYSIS_VIEWED: {
		NAVBAR: { name: 'CD Analysis Viewed', options: { source: 'NavBar' } },
		YOUR_ORDER_LIST: {
			name: 'CD Analysis Viewed',
			options: { source: 'YourOrdersList' },
		},
		VIEWED_ANALYSIS_RESULTS: { name: 'Viewed Analysis Results' },
		VIEWED_ANALYSIS_STATUS: { name: 'Viewed Analysis Status' },
	},

	HOME_VIEWED: { name: 'CD Home Viewed' },

	CRYOGENIC_VIEWED: {
		NAVBAR: { name: 'CD Cryogenic Viewed', options: { source: 'NavBar' } },
		ANALYSIS_CRYO_DETAILS: {
			name: 'CD Cryogenic Viewed',
			options: { source: 'AnalysisCryoDetails' },
		},
		STI_CRYO_DETAILS: {
			name: 'CD Cryogenic Viewed',
			options: { source: 'STIAnalysisPage' },
		},
	},

	SCHEDULE_RESULTS_CALL: {
		CLIENT_DASH_ANALYSIS_PAGE: {
			name: 'Schedule Results Call',
			options: { source: 'ClientDashAnalysis' },
		},
		CLIENT_DASH_NAV_BAR: {
			name: 'Opened Consultations Modal',
			options: { location: 'navigation' },
		},
	},

	VISITED_COMMUNITY_HOME: {
		CLIENT_DASH_NAV_BAR: {
			name: 'Visited Community Home',
			options: { source: 'ClientDashNavBar' },
		},
	},

	ADDED_AUTHORIZED_USER: { name: 'Added Authorized User' },

	VISITED_PAGE: {
		WILL_TEST_TELL_ME_IF_I_CAN_HAVE_KIDS: {
			name: 'Visited Will this test tell me if I can have kids?',
		},
		SPERM_QUALITY_DECREASES: {
			name: 'Sperm quality decreases nearly 60% from your 30s to your 50s',
		},
		THE_SEMENT_ANALYSIS_PROCESS_UNDER_THE_MICROSCOPE: {
			name: 'Visited The semen analysis process under the microscope',
		},
		WHAT_IS_SPERM: { name: 'Visited What is sperm' },
		HOW_CAN_I_IMPROVE_MY_FERTILITY: { name: 'How can I improve my fertility' },
		IN_72_DAYS: { name: "Visited In 72 days you'll have brand new sperm" },
		SPERM_FREEZING_101: { name: 'Visited Sperm freezing 101' },
		SPERM_FREEZING_IN_ACTION: { name: 'Visited Sperm freezing in action' },
	},

	ONBOARDING_CONTRACT_SIGNED: { name: 'Onboarding Contract Signed' },
	ORDER_ANOTHER_KIT: { name: 'Clicked Order Another Kit' },
	ANSWERED_FREEZING_QUESTION: { name: 'Answered Freezing Question' },
	DELIVERY_DATE_SCHEDULED: { name: 'Delivery Date Scheduled' },
	ACCOUNT_SETUP_COMPLETED: { name: 'Account Setup Completed' },
	VIAL_WITHDRAWAL_START: { name: 'Started Vial Withdrawal' },
	VIAL_WITHDRAWAL_REQUEST_COMPLETED: {
		name: 'Vial Withdrawal Request Completed',
	},
	INITIATE_CHECKOUT: { name: 'InitiateCheckout' },
	INSURANCE_QUESTIONS_OPENED: { name: 'Insurance Questions Opened' },
	ONBOARDING_CONTRACT_OPENED: { name: 'Onboarding Contract Opened' },
	CONTACT: { name: 'Contact' },
	INSURANCE_ELIGIBILITY_CARD_CLICKED: {
		name: 'Insurance Eligibility Check Clicked',
	},
	ORDER_COMPLETED: { name: 'Order Completed' },
	FOR_TOMORROW_CONVERSION: { name: 'For Tomorrow Conversion' },
	FOREVER_CONVERSION: { name: 'For Forever Conversion' },
	CRYO_CONVERSION: { name: 'Cryostorage Conversion' },
	OVERALL_CONVERSION: { name: 'Overall Conversion' },

	CLICKED_SELECT_STORAGE_PLAN: {
		HOME: { name: 'Clicked Select Storage Plan', options: { source: 'Home' } },
		ANALYSIS: {
			name: 'Clicked Select Storage Plan',
			options: { source: 'Analysis' },
		},
	},

	CLICKED_MFS_ADD_TO_CART: {
		name: 'Clicked MFS Add to cart',
		options: { location: 'results' },
	},
	CLICKED_COQ10_ADD_TO_CART: {
		name: 'Clicked CoQ10 Add to cart',
		options: { location: 'results' },
	},

	REGISTER_KIT: { name: 'Register Kit CD' },
	REGISTER_KIT_FINISHED: { name: 'Kit  Registration Completed' },

	CLICKED_SPERM_TESTING_DETAILS: { name: 'Clicked Sperm Testing Details' },

	STORAGE_PLAN_SETUP: {
		CLIENT_DASH_HOME: {
			name: 'Started Storage Plan Setup',
			options: { source: 'ClientDashHome' },
		},
		CLIENT_DASH_CRYOGENIC: {
			name: 'Started Storage Plan Setup',
			options: { source: 'ClientDashCryogenic' },
		},
		AFTER_CHECKOUT: {
			name: 'Started Storage Plan Setup',
			options: { source: 'AfterPurchase' },
		},
		FINISHED: { name: 'Storage Plan Setup Completed' },
	},
	LEARN_MORE: { name: 'Clicked Reminders STI' },
	ADD_SOMEONE: { name: 'Clicked Reminders Auth User' },
	CHOOSE_A_PLAN: { name: 'Clicked Choose a Cryostorage Plan' },
	SPERM_STORAGE_INFO: { name: 'Clicked Sperm Storage Info' },
	FROZEN_SPERM_INFO: { name: 'Clicked Using Frozen Sperm Info' },
	MANAGE_BILLING_PAGE: { name: 'CD Manage Billing Viewed' },
	FIND_A_UROLOGIST_PAGE: { name: 'CD Find A Urologist Viewed' },

	ORDER_HISTORY_PAGE: { name: 'CD Order History Viewed' },
	CONTRACTS_PAGE: { name: 'CD Contracts Viewed' },
	ACCOUNT_INFORMATION_PAGE: { name: 'CD Account Information Viewed' },

	CHECKOUT_STARTED: { name: 'Checkout Started' },
	PAYMENT_INFO_ENTERED: { name: 'Payment Info Entered' },
	CHECKOUT_STEP_COMPLETED: { name: 'Checkout Step Completed' },
	CHECKOUT_STEP_VIEWED: { name: 'Checkout Step Viewed' },
	CART_VIEWED: { name: 'Cart Viewed' },

	DOWNLOAD_RESULTS_PDF: {
		STI_RESULT: {
			name: 'Download Results PDF',
			options: { source: 'STIAnalysisPage' },
		},
		ANALYSIS_RESULT: {
			name: 'Download Results PDF',
			options: { source: 'SemenAnalysisPage' },
		},
	},

	ANALYSIS_RESULTS_MODAL_VIEWED: {
		UNDERSTANDING_YOUR_RESULTS: {
			name: 'Opened Analysis Details Modal',
			options: { location: 'fertility overview', source: 'SemenAnalysisPage' },
		},
		FUTURE_HEALTH_INSIGHTS: {
			name: 'Opened Future Health Insights Modal',
			options: {
				location: 'future health insights',
				source: 'SemenAnalysisPage',
			},
		},
		TOTAL_COUNT: {
			name: 'Opened Analysis Details Modal',
			options: { location: 'total count', source: 'SemenAnalysisPage' },
		},
		TOTAL_MOTILE_COUNT: {
			name: 'Opened Analysis Details Modal',
			options: { location: 'total motile count', source: 'SemenAnalysisPage' },
		},
		VOLUME: {
			name: 'Opened Analysis Details Modal',
			options: { location: 'volume', source: 'SemenAnalysisPage' },
		},
		CONCENTRATION: {
			name: 'Opened Analysis Details Modal',
			options: { location: 'concentration', source: 'SemenAnalysisPage' },
		},
		MOTILITY: {
			name: 'Opened Analysis Details Modal',
			options: { location: 'motility', source: 'SemenAnalysisPage' },
		},
		MORPHOLOGY: {
			name: 'Opened Analysis Details Modal',
			options: { location: 'morphology', source: 'SemenAnalysisPage' },
		},
		DNA_FRAGMENTATION: {
			name: 'Opened Analysis Details Modal',
			options: { location: 'dna fragmentation', source: 'SemenAnalysisPage' },
		},
		CRYOGENIC_STORAGE: {
			name: 'Opened Analysis Details Modal',
			options: { location: 'cryogenic storage', source: 'SemenAnalysisPage' },
		},
		PERSONAL_RECOMMENDATIONS: {
			name: 'Opened Analysis Details Modal',
			options: {
				location: 'personal recommendations',
				source: 'SemenAnalysisPage',
			},
		},
		LAB_RECOMMENDATIONS: {
			name: 'Opened Analysis Details Modal',
			options: { location: 'lab observations', source: 'SemenAnalysisPage' },
		},
	},

	STI_RESULTS_MODAL_VIEWED: {
		UNDERSTANDING_YOUR_RESULTS: {
			name: 'Opened Analysis Details Modal',
			options: { location: 'fertility overview', source: 'STIAnalysisPage' },
		},
		CHLAMYDIA: {
			name: 'Opened Analysis Details Modal',
			options: { location: 'chlamydia', source: 'STIAnalysisPage' },
		},
		GONORRHEA: {
			name: 'Opened Analysis Details Modal',
			options: { location: 'gonorrhea', source: 'STIAnalysisPage' },
		},
		HEPATITIS_B: {
			name: 'Opened Analysis Details Modal',
			options: { location: 'hepatitis B', source: 'STIAnalysisPage' },
		},
		HEPATITIS_C: {
			name: 'Opened Analysis Details Modal',
			options: { location: 'hepatitis C', source: 'STIAnalysisPage' },
		},
		HIV: {
			name: 'Opened Analysis Details Modal',
			options: { location: 'hiv', source: 'STIAnalysisPage' },
		},
		SYPHILIS: {
			name: 'Opened Analysis Details Modal',
			options: { location: 'syphilis', source: 'STIAnalysisPage' },
		},
	},

	SEE_STORAGE_OPTIONS: {
		STI_RESULT: {
			name: 'Clicked See storage options',
			options: { source: 'STIAnalysisPage' },
		},
	},

	LEARN_ABOUT_FREEZING: {
		STI_RESULT: {
			name: 'Clicked Learn more about sperm freezing',
			options: { source: 'STIAnalysisPage' },
		},
	},

	PRODUCT_ADDED: {
		name: 'Product Added',
		CONSULTATION_MODAL: { location: 'consultations modal' },
		RESULTS: { location: 'results' },
		UPSELL: { location: 'cart_upsell' },
	},

	PRODUCT_REMOVED: {
		name: 'Product Removed',
	},

	SCHEDULE_CONSULTATION: {
		CONSULTATION_MODAL: {
			name: 'Schedule Consultation',
			options: { location: 'consultations modal' },
		},
		RESULTS: {
			name: 'Schedule Consultation',
			options: { location: 'results' },
		},
	},

	LIFESTYLE_QUIZ: {
		RESULTS: { name: 'Open Lifestyle Quiz', options: { location: 'results' } },
		HOMEPAGE_REMINDER: {
			name: 'Open Lifestyle Quiz',
			options: { location: 'homepage' },
		},
		ANALYSIS: {
			name: 'Open Lifestyle Quiz',
			options: { location: 'analysis' },
		},
		MENU: { name: 'Open Lifestyle Quiz', options: { location: 'menu' } },
	},

	EMAIL_VALIDATION_FAILED: {
		name: 'Email Validation Failed',
		options: { source: 'Checkout' },
	},
}
