import { ActionTree, Module } from 'vuex'
import { RootState } from '@/store'
import axios from 'axios'

export type State = {}

export const state = (): State => ({})

const actions: ActionTree<State, RootState> = {
	async redirectToCalendly({}, requestData): Promise<any> {
		let { data } = await axios.get('/schedule-results-call-link', requestData)
		if (!data) {
			return
		}

		window.open(data, '_self')
	},
}

const module: Module<State, RootState> = {
	namespaced: true,
	state,
	actions,
}

export default module
