<template>
	<TransitionRoot
		as="template"
		:show="modelValue"
	>
		<Dialog
			as="div"
			class="fixed z-50 inset-0 overflow-hidden"
		>
			<div class="flex items-end justify-center min-h-screen pb-20 text-center sm:block sm:p-0">
				<TransitionChild
					as="template"
					enter="ease-out duration-300"
					enter-from="opacity-0"
					enter-to="opacity-100"
					leave="ease-in duration-150"
					leave-from="opacity-100"
					leave-to="opacity-0"
				>
					<DialogOverlay
						class="fixed inset-0 bg-evergreen-800 bg-opacity-75 transition-opacity"
						@click="onClose"
					/>
				</TransitionChild>

				<!-- This element is to trick the browser into centering the modal contents. -->
				<span
					class="hidden sm:inline-block sm:align-middle sm:h-screen"
					aria-hidden="true"
					>&#8203;</span
				>

				<TransitionChild
					as="template"
					enter="ease-out duration-300"
					enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					enter-to="opacity-100 translate-y-0 sm:scale-100"
					leave="ease-in duration-150"
					leave-from="opacity-100 translate-y-0 sm:scale-100"
					leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
				>
					<div
						:class="[hideOverflowY ? 'overflow-y-hidden' : 'overflow-y-auto']"
						class="inline-block align-bottom text-left transform transition-all sm:align-middle"
						role="dialog"
						aria-modal="true"
						aria-labelledby="modal-headline"
					>
						<div
							class="dialog-container"
							:class="$attrs.class"
						>
							<div
								v-if="closeable"
								class="dialog-close-button absolute top-0 right-0 pt-4 pr-4"
							>
								<CloseButton @click="onClose" />
							</div>

							<slot name="title">
								<DialogTitle
									v-if="title"
									as="h3"
									class="mb-8"
								>
									{{ title }}
								</DialogTitle>
							</slot>

							<slot />

							<div
								v-if="$slots.footer"
								class="flex-1 flex items-end mt-8 w-full"
							>
								<slot name="footer"></slot>
							</div>
						</div>
					</div>
				</TransitionChild>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CloseButton from '@/components/common/buttons/CloseButton.vue'
import {
	Dialog,
	DialogOverlay,
	DialogTitle,
	TransitionChild,
	TransitionRoot,
} from '@headlessui/vue'

export default defineComponent({
	name: 'BaseDialog',
	inheritAttrs: false,
	components: {
		Dialog,
		DialogOverlay,
		DialogTitle,
		TransitionChild,
		TransitionRoot,
		CloseButton,
	},
	props: {
		modelValue: Boolean,
		title: {
			type: String,
		},
		appendToBody: {
			type: Boolean,
			default: false,
		},
		hideOverflowY: {
			type: Boolean,
			default: false,
		},
		closeable: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['update:modelValue', 'close'],
	data() {
		return {
			internalShow: false as boolean,
		}
	},
	methods: {
		onClose() {
			if (!this.closeable) {
				return
			}
			this.$emit('update:modelValue', false)
		},
	},
	watch: {
		modelValue: {
			immediate: true,
			async handler(value: boolean) {
				await this.$nextTick()
				this.internalShow = value
			},
		},
	},
})
</script>
<style scoped lang="scss">
.dialog-container {
	@apply flex flex-col bg-white shadow-xl px-8 pb-16 pt-24 md:px-16 w-full relative overflow-y-auto;

	min-height: 400px;
	max-height: 100vh;
	@screen lg {
		width: min(100vw, 736px);
		max-height: calc(100vh - 100px);
	}
}

.dark .dialog-container {
	@apply bg-evergreen-800 shadow-xl-dark;
}
</style>
